<template>
  <div class="c-img u-flex u-middle">
    <img v-if="isBitmap" :src="fakeSRC" >
    <!-- <div v-else v-html="markup" class="span">
    </div> -->
    <app-icon v-else-if="isVector" :width="width" :height="height" :margins="margins" :id="id" />
    <span v-else></span>
  </div>
</template>

<style lang="stylus">
  .c-img
    opacity 0
    transform translateZ(0)
    
    &.is-loaded
      opacity 1
      transition opacity .5s
</style>

<script>
import { api } from '@/store/interfaces/apiCraft'
import AppIcon from '@/components/common/AppIcon'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    margins: {
      type: Number,
      default: 100
    }
  },

  data () {
    return {
      src: '',
      isInViewport: false,
      isBitmap: true,
      isVector: false,
      markup: ''
    }
  },

  computed: {
    fakeSRC() {
      return this.isInViewport ? this.src : "";
    },
  },

  components: {
    AppIcon
  },

  created () {
    // this.getImage()
  },

  mounted () {
    this.getImage()
    this.onResize()
  },

  methods: {
    async getImage () {

      if (!this.id) { 
        this.isBitmap = false
        this.isVector = false
        
        return 
      }
      
      const args = {id: this.id}

      if (this.width !== 0) {
        args.width = this.width
      }
      if (this.height !== 0) {
        args.height = this.height
      }

      const { cache, request } = api.imgSize(args)

      cache.then(this.treatImage)
      request.then(this.treatImage)
    },

    async treatImage (img) {
      if (img === null) { return }

      img = img.replace('$PRIMARY_SITE_URL', process.env.VUE_APP_CRAFT)
      this.src = img

      if (this.src.endsWith('.svg')) {
        
        this.isBitmap = false
        this.isVector = true
        const response = await fetch(this.src)
        const result = await response.text()

        this.markup = result
      } else if ( this.src.endsWith('.png') || this.src.endsWith('.jpg') ) {
        this.isBitmap = true
        this.isVector = false
      }

      this.$emit('src', img)
      this.$el.classList.add('is-loaded')      
    },

    onResize() {
      this.offset = this.$offset.absolute(this.$el)
      this.onScroll()
    },

    onScroll() {
      if( this.isInViewport ) { return }

      this.isInViewport = this.getIsInViewport(this.offset.top - this.margins, Infinity)
    },

    getIsInViewport(offsetTop, height) {
      if( offsetTop + height > this.$device.scroll.top && offsetTop < this.$device.scroll.top + this.$device.height ) {
        return true;
      }
      else {
        return false;
      }
    }
  },

  watch: {
    id () {
      this.getImage()
    },
    width () {
      this.getImage()
    },
    height () {
      this.getImage()
    }
  }
}
</script>