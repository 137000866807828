<template>
  <router-link @click.stop="null" 
    :to="listMode 
      ? { name: 'ListingRealisation', params: { realisationName: article.slug } }
      : '/region/' + regionSlug + '/realisation/' + article.slug
    " 
    append 
    class="card u-pad-mmd u-pad-md--sm u-radius u-shadow--wide u-block">
    <div v-if="article.btCatReal?.[0]" class="tag u-uppercase t-gotham u-row u-shadow--small">
      <app-image :style="{ 'color': article.btCatReal?.[0]?.color }" :id="article.btCatReal?.[0]?.image?.[0]?.id" :height="15" class="u-marg-r-sm"/>
      <span class="card__cat-title">{{ article.btCatReal?.[0]?.title }}</span>
    </div>
    <h2 class="t-h2 t-extrabold u-marg-t-md u-marg-t-sm--sm">{{ article.title }}</h2>
    <section class="meta u-row">
      <!-- <span>{{ article.author.fullName }} -&nbsp;</span>
      <span class="u-dark-grey" >{{ formatDate(article.dateCreated) }}</span> -->
      <span class="meta__readtime u-marg-l-0--sm u-flex u-bottom u-marg-t-md u-marg-t-xs--sm" :style="{color: article.btCatReal?.[0]?.color}">{{ (article.readTime ?? readTime) + ' min de lecture' }}</span>
    </section>
  </router-link>
</template>

<script setup>
import { useRoute } from 'vue-router'
import AppImage from '@/components/common/AppImage'
import { computed } from 'vue';
import { getReadTime } from '@/utils/readtime'
import france from '../../webGL/objects/France';

const route = useRoute()

const props = defineProps({
  article: {
    type: Object,
    required: true
  },
  listMode: {
    type: Boolean,
    default: false
  }
})

const readTime = computed(() => {
  return getReadTime(props.article.wysiwyg)
})

const regionSlug = route.params.regionName ?? france.getRegionByEntryName(props.article.region.title)?.lowerName

</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

/* .card */
.card__cat-title
  +mq($until: 'tablet')
    max-width 85%
.meta
  line-height: 1.5
  &__readtime
    margin-left auto
    +mq($until: 'tablet')
      margin-left: 0
      font-size: 10px
</style>