<template>
  <div class="panel__search u-relative u-inline-block u-pad-x-md u-pad-y-xs u-shadow u-marg-t-xl u-marg-b-lg u-middle u-row">
    <img src="/images/search.svg" alt="" class="info__search__ico u-absolute u-marg-l-sm">
    <input :value="content" @input="handleInput" type="text" name="search" placeholder="Vous avez une recherche précise ?" class="info__search__input t-input t-input--small u-pad-l-lg u-full-width">
  </div>
</template>

<script setup>
import { ref } from 'vue';

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: String
})

const content = ref(props.modelValue)

const handleInput = (e) => {
  content.value = e.target.value
  emits('update:modelValue', content.value)
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.panel__search
    width calc(100% - 20px)
    border-radius 50px
    max-width 500px
    &__ico
      width 19px
      top 50%
      left 0
      transform translateY(-50%)
    
    // &__input
    //   font-weight $weights.light
    //   font-size 12px
      
      // &::-webkit-input-placeholder
      //   font-size 12px
      //   font-weight $weights.light
      //   color $colors.grey-light


</style>