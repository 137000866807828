import { Vector3 } from "three"

export default class RegionPan {
  constructor(camera, webGL) {

    this.camera = camera
    this.webGL = webGL

    this.state = {
      isPointerdown: false,
      initialPos: { x: 0, y: 0 },
    }
    this.downCallbacks = []
    this.upCallbacks = []

    this.basePos = new Vector3()

    this._isActive = false

    this.regionBox = null

    this.blockOob = false
  }

  set isActive (value) {
    value ? document.body.style.cursor = 'grab' : document.body.style.cursor = 'initial'
    this._isActive = value
  }

}