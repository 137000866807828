<template>
  <div class="debugger" @click="onClick">
    <div ref="interactor" class="interactor u-wrapper-panel"></div>
    <device />
    <div v-if="targetPos.x !== 0 || targetPos.y !== 0 || targetPos.z !== 0 || step !== 0" class="debugger__interface u-pad-sm u-bg-white u-black">
      <div class="device-info__title">
        Position
      </div>
      <div class="u-marg-t-sm">
        <div @click="onCopy(targetPos.x)">x: {{ targetPos.x.toFixed(2) }}</div>
        <div @click="onCopy(targetPos.y)">y: {{ targetPos.y.toFixed(2) }}</div>
        <div @click="onCopy(targetPos.z)">z: {{ targetPos.z.toFixed(2) }}</div>
      </div>
      <template v-if="step === 1">
        <div class="device-info__title u-marg-t-sm">
          Directionnal
        </div>
        <div class="u-marg-t-sm">
          <div @click="onCopy(delta.x)">x: {{ delta.x.toFixed(2) }}</div>
          <div @click="onCopy(delta.y)">y: {{ delta.y.toFixed(2) }}</div>
          <div @click="onCopy(delta.z)">z: {{ delta.z.toFixed(2) }}</div>
        </div>
        <button class="u-marg-t-sm" @click="onReset">
          Back to moving freely
        </button>
        <!-- I comment that because not usefull
        <div class="device-info__title">
          Distance
        </div>
        <div class="u-marg-t-sm">
          {{ distance.toFixed(2) }}
        </div> -->
      </template>
    </div>
    <div class="debugger__infos u-pad-sm u-bg-white u-black">
      <div v-if="step === 0">
        MOUSE LEFT to rotate camera <br />
        CTRL + MOUSE LEFT to pan camera <br />
        SPACE BAR + MOUSE LEFT to set cameras' target position
      </div>
      <div v-else-if="step === 1">
        MOUSE LEFT to rotate camera <br />
        Scroll to zoom in/out
        SPACE BAR + MOUSE LEFT to adjust cameras' target position
        MOUSE LEFT on value to copy its value 
      </div>
    </div>
    <button v-if="step === 0" class="debugger__validate u-marg-t-sm" @click="onValidate">
      Validate
    </button>
  </div>
</template>

<script>
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { webGL } from '@/webGL/WebGL'

import Device from './Device'
import CopyPaste from '@/utils/CopyPaste'

import {
  SphereGeometry,
  MeshBasicMaterial,
  Mesh
} from 'three'

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: .1
    }
  },

  data () {
    return {
      step: 0,
      deviceInfo: false,
      targetPos: {
        x: 0,
        y: 0,
        z: 0
      },
      delta: {
        x: 0,
        y: 0,
        z: 0
      },
      distance: 0
    }
  },

  components: {
    Device
  },

  created () {
    this.isSpaceDown = false
  },

  mounted () {
    this.controls = new OrbitControls(webGL.camera, this.$refs.interactor)
    this.controls.target.copy(webGL.camera.look)
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.1

    this.target = new Mesh(new SphereGeometry(this.size), new MeshBasicMaterial({color: 0x0000aa}))

    webGL.scene.add(this.target)

    window.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('keyup', this.onKeyUp)
  },

  methods: {
    onUpdate () {
      this.controls.update()

      if (this.step === 1) {
        const delta = webGL.camera.position.clone().sub(this.target.position)
        delta.normalize()
        this.distance = delta.length()

        this.delta.x = delta.x
        this.delta.y = delta.y
        this.delta.z = delta.z
      }
    },
    onClick (event) {
      const size = {
        width: this.$refs.interactor.offsetWidth,
        height: this.$refs.interactor.offsetHeight
      }

      this.$device.setRaycast(event, webGL.camera, size, this.$offset.absolute(this.$refs.interactor))

      if (this.isSpaceDown) {
        this.target.position.copy(this.$device.getIntersectPosition(webGL.scene.children))

        this.targetPos.x = this.target.position.x
        this.targetPos.y = this.target.position.y
        this.targetPos.z = this.target.position.z

        if (this.step === 1) {
          this.updateTarget()
        }
      }
    },

    onKeyDown (event) {
      if (event.keyCode === 32) {
        this.isSpaceDown = true
        this.target.material.color.set(0x00aa00)
      }
    },

    onKeyUp (event) {
      this.isSpaceDown = false
      this.target.material.color.set(0x0000aa)
    },

    onValidate () {
      this.step = 1
    },

    updateTarget () {
      this.controls.target.copy(this.target.position)
      this.controls.enablePan = false
      this.controls.update()
    },

    onCopy (arg) {
      CopyPaste.makeCopy({ values: arg })
    },

    onReset (arg) {
      this.step = 0
    }
  },

  watch: {
    step () {
      if(this.step === 0) {
        this.controls.enablePan = true
        this.controls.update()
      } else if (this.step === 1) {
        this.updateTarget()
      }
    }
  }
}
</script>



<style lang="stylus">

.debugger
  position absolute
  top 0
  left 0
  height 100%
  width 100%

  &__interface
    position absolute
    bottom 0
    right 0
    border-radius 15px 0 0 0
    max-width 400px
    
    &__title
      font-size 1.2em
      font-weight bold

  &__infos
    position absolute
    top 0
    left 0
    border-radius 0 0 15px 0
    max-width 400px
    font-size .8em

  &__validate
    position absolute
    bottom 50px
    left 50%
    padding .5em 1.2em
    border-radius 10px
    font-size 1.2em
    transform translateX(-50%)
    font-weight bold
  
</style>