<template>
  <div v-if="$device.isMobile" class="u-wrapper-panel u-bg-white u-z-front u-pad-t-md u-pad-x-lg u-overflow-y-scroll u-half-height--vert u-middle--vert u-column--vert u-flex--vert u-center--vert real-panel">
    <div class="u-column u-center">
      <button class="close u-bg-red u-inline-flex u-pad-md u-round u-marg-b-md" @click="emit('close')">
        <svg class="u-fit-contain u-stroke-white" >
          <use xlink:href="#cross"/>
        </svg>
      </button>
    </div>
    <div class="u-column u-top-md u-right-md u-center--vert real-categories">
      {{ entries.filter(e => e.typeHandle === 'btKReal').length }} réalisations
      <div 
        @click.stop="() => toggleCategory(category.id)" 
        class="category u-row u-middle u-between t-text--med t-montserrat u-relative u-shadow" v-for="category in categories"
        :data-color="category.color"
        :class="{ 'is-selected': isSelected(category.id) }"
        :style="{ backgroundColor: isSelected(category.id) ? category.color : 'white' }"
        :key="category.id">
        <div 
          class="category__item-count u-relative u-shadow u-full-height u-inline-block"
          :style="{ color: category.color }"
          >
          {{ category.count }}
        </div>  
        <app-image 
          class="category__picto u-marg-r-sm" 
          :id="category.image?.[0]?.id" 
          :height="15"
          :style="{ 'color': category.color }"
          alt=""/>
        <span class="category__title"> {{  category.title }} </span>
      </div>
    </div>
  </div>
  <div v-else class="u-column u-absolute u-top-md u-right-md u-z-front real-categories">
    <span class="t-text--med u-inline-block u-marg-b-sm">{{ entries.filter(e => e.typeHandle === 'btKReal').length }} réalisations</span>
    <div 
      @click.stop="() => toggleCategory(category.id)" 
      class="category u-row u-middle u-between t-text--med t-circular u-relative u-shadow" v-for="category in categories"
      :data-color="category.color"
      :class="{ 'is-selected': isSelected(category.id) }"
      :style="{ backgroundColor: isSelected(category.id) ? category.color : 'white' }"
      :key="category.id">
      <div 
        class="category__item-count u-relative u-shadow u-full-height u-inline-block"
        :style="{ color: category.color }"
        >
        {{ category.count }}
      </div>  
      <app-image 
        class="category__picto u-marg-r-sm" 
        :id="category.image?.[0]?.id" 
        :style="{ 'color': isSelected(category.id) ? 'white' : category.color }"
        :height="15"
        alt=""/>
      {{  category.title }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import AppImage from '@/components/common/AppImage'

const store = useStore()
const categories = computed(() => {
  return store.getters['data/realisationCategories']
})

const selectedCategories = computed(() => {
  return store.state.global.selectedRealCategories
})

const isSelected = (id) => {
  if (selectedCategories.value.length === 0) {
    return true
  }
  return selectedCategories.value.includes(id)
}

const entries = computed(() => {
  return store.state.data.entries
})

const toggleCategory = (category) => {
  const categories = store.state.global.selectedRealCategories
  if (categories.includes(category)) {
    store.commit('global/removeSelectedRealCategory', category)
  } else {
    store.commit('global/addSelectedRealCategory', category)
  }
}

const emit = defineEmits(['close'])
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.close
  margin: auto
  svg
    width: 24px
    height: 24px
    cursor: pointer

.real-panel
  z-index 2000 !important

.real-categories
  top 187px
  right 50px
  max-height: calc(100vh - 187px);
  overflow-y: auto;
  padding-left: 12px;

  text-transform uppercase
  .category
    width max-content
    padding: 10px 15px
    padding-left 2.3rem
    margin: 15px
    border-radius: 10px 1.4rem 1.4rem 10px
    cursor: pointer
    opacity 1
    background-color: #fff
    color: #000
    &:hover
      background-color: #fff
      color: #000
    &.is-selected
      color: white
      .category__picto
        // filter brightness(3.5)
        // filter invert(1)
        color white

    &__picto
      // width: 2rem
      // height: 2rem
      // margin: 0.5rem
      margin-left 0
      max-height: 14px
      

    &__item-count
      position: absolute;
      left: -20px;
      padding: 18px;
      border-radius: 50px;
      background: white;
      width: 38px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

+mq($until: 'desktop')
  .category__title
    max-width 45vw
+mq($from: 'desktop', $vertical: true)
  .real-panel
    top 50% !important
  .category
    margin-left auto !important
    margin-right auto !important
</style>