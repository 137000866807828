import { Box3, Box3Helper, ShaderMaterial } from 'three'
import gsap from 'gsap'
import { Component } from 'shimmer'
import france from './France'

export const haloMaterial = new ShaderMaterial({
  transparent: true,
  depthWrite: false,
  uniforms: {
    opacity: { value: 0. },
    attenuationFactor: { value: 1. },
    // height: { value: dimensions.max.y - dimensions.min.y }
  },
  vertexShader: require('@/webGL/shaders/borders.vert').default,
  fragmentShader: require('@/webGL/shaders/borders.frag').default,
  // side: DoubleSide
})

/**
 * @class Project
 * Represents a region. Instanced only if its Craft counterpart exists.
 * @extends {Object3D}
 */
export default class Region extends Component {
  constructor(regionMesh, regionEntry, isDomTom) {
    super()
    this.name = 'Region'
    this.lowerName = ''
    this.contour = null
    this.hitbox = null
    this.hitboxMesh = null
    this.halo = null
    this.entry = regionEntry
    this.bbox = null
    this.isDomTom = isDomTom
    this.listeners = {}
    if (regionMesh) this.setRegionMesh(regionMesh)
    this.setRegionMesh = this.setRegionMesh.bind(this)
  }

  setRegionMesh(mesh) {
    this.mesh = mesh
    this.setLowerName()
    this.mapMembers()
    this.createHalo()
    this.hideHitbox()
    this.hideHalo()
    this.unsetAutoMatrix()
  }

  componentify() {
    this.mesh.removeFromParent()
    this.add(this.mesh)
    france.mapObject.add(this)
  }

  setLowerName() {
    this.lowerName = this.mesh.name.replace('group_', '')
  }

  mapMembers(){
    this.contour = this.mesh.getObjectByName('contour_'+this.lowerName)
    this.hitbox = this.mesh.getObjectByName('hitbox_region_'+this.lowerName) ?? this.mesh.getObjectByName('hitbox_region_'+this.lowerName+'_Baked')
    
    if (this.contour === undefined) this.mesh.traverse(child => { 
      if (child.name.startsWith('contour_')) {
        const contourRegionName = child.name.replace('contour_', '')

        if ( child.name.includes(this.lowerName) || this.lowerName.includes(contourRegionName) ) this.contour = child
      }
    })

    if (this.hitbox === undefined) this.mesh.traverse(child => { 
      if (child.name.startsWith('hitbox_')) {
        const hitboxRegionName = child.name.replace('hitbox_region_', '').replace('_Baked', '')

        if ( child.name.includes(this.lowerName) || this.lowerName.includes(hitboxRegionName) ) this.hitbox = child
      }
    })

    // "hitbox_" is just a container for the actual hitbox
    if (this.hitbox) // DOM TOM has no contour
      this.hitboxMesh = this.hitbox.children[0]
  } 

  createHalo() {

    if (!this.mesh.getObjectByName('halo') && this.contour) {

      // DOM TOM has no contour
      const halo = this.contour 
        ? this.contour.children[0].clone()
        : this.mesh.clone()

      halo.material = haloMaterial.clone()
      
      halo.position.y += 0.0025
      
      halo.scale.y *= 2
      halo.scale.x *= 0.9999
      halo.scale.z *= 0.9999
      
      halo.name = 'halo'

      // DOM TOM has no contour
      if (this.contour) this.contour.add(halo)
      else this.add(halo)

      this.halo = halo

    }

    else {

      this.halo = this.mesh.getObjectByName('halo')

    }
  }

  hideHitbox() {
    if (this.hitbox) this.hitbox.visible = false
  }

  hideHalo () {
    this.toggleHalo(false)
  }
  
  showHalo () {
    this.toggleHalo(true)
  }
  
  toggleHalo(isVisible) {
    if (!this.halo) return
    gsap.to(this.halo.material.uniforms.opacity, {
      value: isVisible ? 0.5 : 0,
      duration: 0.5,
      ease: 'power4.inOut'
    })
  }

  unsetAutoMatrix() {
    this.mesh.traverse(child => {
      if (!child.name.startsWith('halo')) child.matrixAutoUpdate = false
    })
  }

  set onClick(listener) {
    if (listener) {
      this.listeners.onClick = listener
      this.on('click', listener, this.hitboxMesh)
    }
    else if (this.listeners.onClick) {
      this.off('click', this.listeners.onClick)
    }
  }

  set onEnter(listener) {
    if (listener) {
      this.listeners.onEnter = listener
      this.on('enter', listener, this.hitboxMesh)
    }
    else if (this.listeners.onEnter) {
      this.off('enter', this.listeners.onEnter)
    }
  }

  set onOut(listener) {
    if (listener) {
      this.listeners.onOut = listener
      this.on('out', listener, this.hitboxMesh)
    }
    else if (this.listeners.onOut) {
      this.off('out', this.listeners.onOut)
    }
  }

  get box() {

    if (!this.bbox) {
      const box = new Box3()
      box.setFromObject(this.mesh)
      this.bbox = box

    }

    const helper = new Box3Helper(this.bbox, 0x00ff00)
    helper.name = 'bbox'
    // this.mesh.add(helper)  

    return this.bbox
    
    // if (!this.hitboxMesh.geometry.boundingBox) this.hitboxMesh.geometry.computeBoundingBox()
    
    // return this.hitboxMesh.geometry.boundingBox
  }

  select() {
    this.showHalo()
  }

  dispose() {
    // if (this.halo) {
    //   this.halo.removeFromParent()
    //   this.halo.geometry.dispose()
    //   this.halo.material.dispose()
    // }
    // if (this.mesh) {
    //   this.mesh.removeFromParent()
    //   france.mapObject.add(this.mesh)
    //   france.mapObject.remove(this)
    //   // this.removeFromParent()
    // }
  }
}