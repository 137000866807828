<template>
  <div class="overlay"></div>
  <div class="read_mode u-fixed  u-bg-white u-shadow--wide u-pad-xl u-pad-md--sm">
    <router-link 
      class="u-bg-white u-flex u-center u-middle u-round u-shadow read_mode__close" 
      :to="listMode ? '/listing' : route.path.replace('/read', '')"
    >
      <svg class="u-stroke-black" >
        <use xlink:href="#cross"/>
      </svg>
    </router-link>
    <div class="u-overflow-y-scroll read_mode__content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';

const props = defineProps({
  listMode: Boolean,
})

const route = useRoute()
</script>

<style scoped lang="stylus">

@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.read_mode
  position fixed
  width 87%
  right 6.5%
  top 162px
  color black
  border-radius 40px 40px 0 0

  &__close
    position absolute
    top -43px
    right calc(50% - 25px)
    width 86px
    height 86px
    display flex
    align-items center
    justify-content center
    svg
      width 24px
      height 24px

  &__content
    height 71vh

.overlay
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color rgba(0,0,0,0.5)
  z-index 0
  // backdrop-filter blur(10px)

+mq($until: 'desktop')
  .read_mode
    top 40px
    border-radius 11px 11px 0 0
    &__content
      height calc(100vh - 40px)
    &__close
      height 42px
      width 42px
      top -21px
      right calc(50% - 21px)
      svg
        width 18px
        height 18px
</style>