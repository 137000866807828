// import '@/mixins/extendedComponent'

import data from '@/assets/data'
import { webGL } from '@/webGL/WebGL'
import { engine, GLTFLoader, LoaderManager, pointer, Component } from 'shimmer'
import { Object3D, Cache, Vector3 } from 'three'
import Hammer from 'hammerjs'
import RegionPan from './utils/RegionPan'
import idleDetect from './utils/idleDetect'
import { cartesianToPolar } from './utils/Maths/cartesianToPolar'
import { mapState } from 'vuex'

export default {

  created () {
    GLTFLoader.ktx = webGL.renderer.renderer
    window.addEventListener('resize', this._onResize)
    window.addEventListener('scroll', this._onScroll)
    window.addEventListener('keydown', this._onKeyDown)

    this.$device._onResize()
    this.$hub.emit('resize', event)

    LoaderManager.config = data.assets

    let _updateMatrixWorld = Object3D.prototype.updateMatrixWorld
    Object3D.prototype.updateMatrixWorld = function () {
      if (!this.visible) {
        return
      }
      _updateMatrixWorld.apply(this)
    }

    const _getIntersectPosition = pointer.getIntersectPosition
    pointer.getIntersectPosition = function (obj) {
      if (obj.max) {
        return pointer.raycaster.ray.intersectsBox(obj)
      }
      try {
        return _getIntersectPosition(obj)
      } catch (error) {
        return new Vector3()
      }
    }

    const __onPointerdown = Component.prototype._onPointerdown
    Component.prototype._onPointerdown = function () {
      setTimeout(() => {
          __onPointerdown.apply(this)
        
      }, 100);
    }

    Cache.enabled = true
    
  },

  mounted () {
    webGL.el = this.$refs.containerWebGL
    engine.el = this.$refs.containerWebGL

    this.$dom3d.onResize(this.$device.width, this.$device.height)
    this.$dom3d.attachTo(this.$refs.containerCSS3D)
    this.$dom3d.setCamera(webGL.camera)
    webGL.resize({ width: this.$device.width, height: this.$device.height })
    engine.camera = webGL.camera

    this._onResize()

    let isPinching

    idleDetect()

    pointer.pointermove({ clientX: 0, clientY: 0 })

    webGL.regionPan = new RegionPan(webGL.camera, webGL)

    engine.el.addEventListener('pointerup', e => {
      if (!isPinching && !this.$store.state.global.isZooming) {
        pointer.pointermove(e)
        engine.pointerdown(e)
      }
    })

    webGL.hammer.on('pinchstart', e => { 
      console.log('pinchstart')
      isPinching = true })
    webGL.hammer.on('pinchend', e => { 
      console.log('pinchend')
      
      setTimeout(() => {
        isPinching = false 
      }, 600);
    })

    const mc = new Hammer.Manager(this.$refs.containerWebGL)
    mc.add(new Hammer.Pan({ threshold: 5, pointers: 0, direction: Hammer.DIRECTION_ALL }))
    mc.on('panstart', e => {
      isPinching = true
    })

    mc.on('panend', e => {
      setTimeout(() => {
        isPinching = false
        this.$store.commit('global/setDragEnd', true)
      }, 600);
    })

    window.addEventListener(this.$device.pointermove, e => {
      this.$store.commit('global/setDragEnd', false)
      engine.pointermove(e)
    })
  },

  methods: {
    onUpdate (time) {
      this._onUpdate(time)
    },

    _onUpdate ({ delta, elapsed }) {
      webGL.update()
      this.$dom3d.update()
      if (this.controls) this.controls.update()
    },

    _onResize (event) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$device._onResize()
        this.$dom3d.onResize(this.$device.width, this.$device.height)
        webGL.resize({ width: this.$device.width, height: this.$device.height })
        this.$hub.emit('resize', event)
      }, 200)
    },

    _onScroll (event) {
      this.$device._onScroll()
      this.$hub.emit('scroll', event)
    },

    _onKeyDown (event) {
      this.$hub.emit('keydown', event.keyCode)
      if (event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27) {
        this.$hub.emit('keydown:escape', event.keyCode)
      }
    },
  }
}
