import { Box3, Vector3 } from "three"
import { Component } from "shimmer"

export default class StandardizedMesh extends Component {
  // en gros, il faut que tu ai l'obj d'add, et là, je l'ai calé sur this.obj
  // pour le normaliser, on manipule this.obj
  constructor (obj) {
    super()
    if (!obj) return // so that clone doesnt fail
    this.name = 'Standard_' + obj.name + '_container'
    this.obj = obj
    this.add(obj)
    this.setScaleTarget()
  }

  // De base, tu lui fait manger un vector3(1, 1, 1)
  // Et aspect, c'est cover ou contain
  setScaleTarget (target = new Vector3(1, 1, 1), aspect = 'cover') {
    const box = this.boundingBox
    const size = this.size
    const width = size.x
    const height = size.y
    const ratioItem = width / height
    const ratioTaget = target.x / target.y
    let scale = 0

    // ITEM WIDER THAN TARGET                           // ITEM HIGHER THAN TARGET
    if (ratioItem > ratioTaget && aspect === 'cover' || (ratioItem < ratioTaget && aspect === 'contain')) {
      scale = (target.y / height)
    }
    // ITEM HIGHER THAN TARGET                           // ITEM WIDER THAN TARGET
    else if ((ratioItem < ratioTaget && aspect === 'cover') || (ratioItem > ratioTaget && aspect === 'contain')) {
      scale = (target.x / width)
    }

    this.positionItem()
    this.obj.scale.set(scale, scale, scale)
  }

  // ça centre le tintouin, tu voudras probablement plutôt le caler sur sa base, je te laisse check si besoin
  positionItem () {
    const center = this.center
    const size = this.size

    this.obj.position.sub(center)
    this.obj.position.y += this.size.y * .5
  }

  static positionObject(obj, aspect = 'cover') {
    const box = new Box3().setFromObject(obj)
    const size = new Vector3()
    box.getSize(size)
    const target = new Vector3(1, 1, 1)

    const width = size.x
    const height = size.y
    const ratioItem = width / height
    const ratioTaget = target.x / target.y
    let scale = 0

    // ITEM WIDER THAN TARGET                           // ITEM HIGHER THAN TARGET
    if (ratioItem > ratioTaget && aspect === 'cover' || (ratioItem < ratioTaget && aspect === 'contain')) {
      scale = (target.y / height)
    }
    // ITEM HIGHER THAN TARGET                           // ITEM WIDER THAN TARGET
    else if ((ratioItem < ratioTaget && aspect === 'cover') || (ratioItem > ratioTaget && aspect === 'contain')) {
      scale = (target.x / width)
    }

    const center = new Vector3()
    box.getCenter(center)
    obj.position.sub(center)
    obj.scale.set(scale, scale, scale)
  }
}