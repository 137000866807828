<template>
  <panel 
    @click.stop="null"
    class="u-main-color"
    :class="{'u-full-width': isFullListingView}"
    @prev="$emit('prev')" 
    @next="$emit('next')" >

    <div>

      <!-- mobile header -->
      <div v-if="$device.isMobile" class="u-full-width u-bg-red">
        <region-slider mode="mobile" @next="$emit('next')" @prev="$emit('prev')" />
      </div>

  
      <h2 v-if="$device.isMobile" class="t-h2 u-marg-t-sm u-marg-b-sm--sm">
        Thèmes de nos réalisations
      </h2>
      <!-- <h2 v-else>

      </h2> -->
      <!-- <filters v-if="!$device.isMobile" :categories="data.categories"/> -->
      

      <h3 v-if="!$device.isMobile" class="t-h2 u-marg-b-lmd t-montserrat">Nos réalisations {{ region.title }}</h3>

      <div class="u-row u-marg-t-xs--sm">
        <article-card 
          :class="'u-marg-b-md u-marg-t-xs u-full-width'"
          v-for="article in entries" 
          :key="article.id" 
          :article="article"/>
      </div>
  
    </div>
    <transition name="t-fader">
      <slot></slot>
    </transition>
    
  </panel>
</template>

<script>  
import MediaType from '@/mixins/MediaType'
import Panel from '@/components/Panel'
import Filters from '@/components/ListPanel/Filters'
import ArticleCard from '@/components/ListPanel/ArticleCard'
import RegionSlider from "@/components/RegionSlider";

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  components: {
    Panel,
    Filters,
    ArticleCard,
    RegionSlider
  },

  mixins: [MediaType],

  emits: ['prev', 'next', 'popin'],

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    isFullListingView: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {

    const route = useRoute()

    const isRealisationView = ref(route.path.includes("realisation"))
    const isListView = computed(() => route.name === 'Regions')

    const store = useStore()

    const search = ref('')

    const regions = computed(() => {
      return store.getters['data/regions']
    })

    const entries = computed(() => {
      return store.getters[
        props.isFullListingView 
          ? 'data/activeFilteredRealisationEntries' 
          : 'data/activeLocalRealisationEntries'
        ].filter(article => article.title.toLowerCase().includes(search.value.toLowerCase()))
    })

    const region = computed(() => {
      return store.state.global.currentRegion
    })

    const isSelected = (id) => {
      return store.state.global.selectedRegions.includes(id)
    }

    const toggleRegion = (regionId) => {
      store.commit('global/toggleSelectedRegion', regionId)
    }

    const updateSearch = (val) => {
      search.value = val
    }

    return { 
      isRealisationView,
      entries,
      isListView,
      search,
      regions,
      isSelected,
      toggleRegion,
      updateSearch,
      region
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.panel
  position absolute
  right 0
  top 0
  height 100%
  width clamp(300px, 30%, 600px)

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )
    background-color #F8F8F8

  &__content
    height calc(100% - 60px)
  
  &__button
    height 30px
    width 30px
  &__accessibility
    padding 40px 0 40px 90%
    +mq($until: 'tablet')
      padding 50px 0 40px 65%
    &__button
      height 18px
      width 27px

  &__card
    &--third
      width calc(94% / 3)
      margin-bottom 20px !important
      margin-right 20px !important
      +mq($until: 'tablet')
        width 94%
</style>