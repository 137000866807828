import { onMounted, onBeforeMount, inject } from 'vue'
import { clock } from 'shimmer'

export default {
  mounted () {
    clock.on(this.onUpdate)
    this.$hub.on('resize', this.onResize)
    this.$hub.on('scroll', this.onScroll)
  },
  beforeUnmount () {
    clock.off(this.onUpdate)
    this.$hub.off('resize', this.onResize)
    this.$hub.off('scroll', this.onScroll)
  },
  methods: {
    onUpdate () {},
    onResize () {},
    onScroll () {}
  }
}

// see https://css-tricks.com/how-the-vue-composition-api-replaces-vue-mixins/
export const useClock = (onUpdate) => {
  onMounted(() => {
    clock.on(onUpdate)
  })

  onBeforeMount(() => {
    clock.off(onUpdate)
  })
}

export function useResize(onResize) {
  const hub = inject('hub')

  onMounted(() => {
    hub.on('resize', onResize)
  })

  onBeforeMount(() => {
    hub.off('resize', onResize)
  })
}

export function useScroll(onScroll) {
  const hub = inject('hub')
  
  onMounted(() => {
    hub.on('scroll', onScroll)
  })

  onBeforeMount(() => {
    hub.off('scroll', onScroll)
  })
}