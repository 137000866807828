import { createWebHistory } from 'vue-router'

import Map from '@/pages/Root'
import Realisation from '@/pages/Region/Project/Project'
import Region from '@/pages/Region/Region'
import messages from './locales'
import SideArticle from '@/components/RealisationPanel/SideArticle'
import FullPanel from "@/components/ListPanel/FullPanel";
import RegionUI from '@/pages/Region/RegionUI'

const routes = [
  {
    path: '/',
    name: 'Map',
    component: Map,
    meta: {
      isFull: true
    },
    children: [
      {
        path: '/listing',
        name: 'Listing',
        component: FullPanel,
        children: [
          {
            path: '/listing/realisation/:realisationName',
            component: SideArticle,
            name: 'ListingRealisation',
          }
        ]
      }
    ]
  },
  { 
    path: '/region/:regionName', 
    name: 'Regions',
    component: Region,
    children: [
      {
        path: '',
        component: RegionUI,
        meta: {
          level: 'Region'
        }
        // name: 'RegionRoot'
      },
      {
        path: '/region/:regionName/realisation/:realisationName',
        name: 'Realisation',
        component: Realisation,
        meta: {
          isProject: true,
        },
        children: [
          {
            path: '',
            name: 'SideArticle',
            component: SideArticle,
          },
          {
            path: '/region/:regionName/realisation/:realisationName/read',
            name: 'SideArticle',
            component: SideArticle,
          },
        ]
      }
    ]
  },
  // {
  //   path: '/region/:regionName',
  //   name: 'Map', 
  //   component: Map
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
