<template>
  <panel
    :isFullWidth="true"
    class="u-main-color u-full-width"
    @prev="$emit('prev')" 
    @next="$emit('next')" >

    <div>

      <div class="u-row panel__accessibility u-pad-b-xxxl--vert">
        <button>
          <svg class="panel__accessibility__button u-icon u-icon-arrow-left u-icon-left u-fill-red u-icon-size-md">
            <use xlink:href="#accessibility-eye"></use>
          </svg>
        </button>
      </div>

      <search v-if="!$device.isMobile" @update:modelValue="updateSearch($event)" :modelValue="search"/>
  
      <h2 class="t-h2 u-marg-t-sm u-marg-b-xs">
        Thèmes de nos réalisations
      </h2>
      <filters :categories="data.categories"/>
      
      <h2 class="t-h2 u-marg-t-sm">
        Régions
      </h2>
      
      <!-- filtres régions -->
      <div class="region-filters u-invisible-scroll u-pad-sm--sm">
        <ul class="u-marg-t-md u-marg-b-lg u-marg-b-md--sm region-filters__inner">
          <li v-for="region in regions" :key="region.id" class="u-inline-block u-marg-r-md">
            <button 
              class="u-pad-sm u-radius u-shadow--small u-marg-b-md t-bold" 
              :class="[isSelected(region.id) ? 'u-white u-bg-red' : '']"
              @click.stop="() => toggleRegion(region.id)">
              {{region.title}}
            </button>
            <!-- <region-category class="u-marg-b-sm" :content="region" /> -->
          </li>
        </ul>
      </div>

      <h2  class="t-h2 u-marg-t-sm">
        Vos résultats
      </h2>

      <div class="u-row u-marg-t-md">
        <article-card 
          :class="'panel__card--third u-marg-b-md u-marg-r-md u-marg-r-0--sm'"
          v-for="article in entries" 
          :key="article.id" 
          :article="article"
          :listMode="true"/>
      </div>
  
    </div>

    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component :is="Component" :listMode="true">
          <div v-if="article.btCatReal?.[0]" class="u-row u-right u-middle">
            <category class="article__category" :content="article.btCatReal?.[0]" :clickable="false"/>
          </div>
          <h1 class="t-h1--mega article__title">{{ article.title}}</h1>
          <youtube-player :isControls="true" class="u-full-width u-radius article__illu u-marg-b-md" v-if="youtubeId" :id="youtubeId" />
          <vimeo-player class="u-full-width u-radius article__illu" v-else-if="vimeoId" :id="vimeoId" />
          <img v-else class="u-full-width u-radius u-fit-cover u-marg-b-lg article__illu" :src="article.image?.[0]?.url" :alt="article.btCatReal?.[0].title">
          <div class="t-text--paragraph col article__content u-marg-b-xxl u-marg-b-md--sm " v-html="article.wysiwyg"></div>
          <div class="other-articles u-flex u-marg-l-xs u-pad-l-sm u-overflow-x-scroll--md">
            <article-card 
              :class="'panel__card--third u-marg-b-md u-marg-r-md'"
              v-for="_article in nextArticles" 
              :key="_article.id" 
              :article="_article"/>
          </div>
        </component>
      </transition>
    </router-view>
    
  </panel>
</template>

<script>  
import MediaType from '@/mixins/MediaType'
import Panel from '@/components/Panel'
import Search from '@/components/ListPanel/Search'
import Filters from '@/components/ListPanel/Filters'
import ArticleCard from '@/components/ListPanel/ArticleCard'
import YoutubePlayer from '../common/players/YoutubePlayer.vue'
import VimeoPlayer from '../common/players/VimeoPlayer.vue'

import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
  components: {
    Panel,
    Search,
    Filters,
    ArticleCard,
    YoutubePlayer,
    VimeoPlayer
  },

  mixins: [MediaType],

  emits: ['prev', 'next', 'popin'],

  props: {
    data : {
      type: Object,
      default: () => { return {} }
    },
    isFullListingView: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {

    const route = useRoute()

    const isRealisationView = ref(route.path.includes("realisation"))
    const isListView = computed(() => route.name === 'Regions')

    const store = useStore()

    const search = ref('')

    const regions = computed(() => {
      return store.getters['data/regions']
    })

    const entries = computed(() => {
      return store.getters[
        props.isFullListingView 
          ? 'data/activeFilteredRealisationEntries' 
          : 'data/activeLocalRealisationEntries'
        ].filter(article => article.title.toLowerCase().includes(search.value.toLowerCase()))
    })

    const isSelected = (id) => {
      return store.state.global.selectedRegions.includes(id)
    }

    const toggleRegion = (regionId) => {
      store.commit('global/toggleSelectedRegion', regionId)
    }

    const updateSearch = (val) => {
      search.value = val
    }

    const article = computed(() => {
      // TODO: refacto to only search in articles with right category
      return store.getters['data/activeRealisationEntries'].find(entry => entry.slug === route.params.realisationName)
    })

    console.log('full panel')
    const youtubeId = computed(() => {
      return article.value?.videoLink?.includes('youtube') ? article.value?.videoLink?.replace('https://www.youtube.com/watch?v=', '').substring(0, 11) : null
    })

    console.log('youtubeId', youtubeId.value)

    const nextArticles = computed(() => {
      const currentIndex = entries.value.findIndex(entry => entry.slug === route.params.realisationName)
      return entries.value.slice(currentIndex + 1, currentIndex + 4)
    })

    return { 
      isRealisationView,
      entries,
      isListView,
      search,
      // articles,
      regions,
      isSelected,
      toggleRegion,
      updateSearch,
      article,
      nextArticles,
      youtubeId,
    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.panel
  position absolute
  right 0
  top 0
  height 100%
  width 100% !important
  // width clamp(300px, 30%, 600px)

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )

  &__content
    height calc(100% - 60px)
  
  &__button
    height 30px
    width 30px
  &__accessibility
    display none
    padding 8px 0 20px 92%
    +mq($until: 'tablet')
      padding 32px 0 40px 65%
    &__button
      height 36px
      width 53px
      +mq($until: 'desktop')
        height 18px
        width 27px

  &__card
    &--third
      width calc(94% / 3)
      margin-bottom 20px !important
      margin-right 20px !important
      +mq($until: 'tablet')
        width 100%
        margin-right 0 !important
.other-articles 
  +mq($until: 'tablet')
    padding 16px
    .panel__card--third
      margin-right 20px !important
      .tag img
        display none

.article__illu
  max-height 500px
  +mq($until: 'desktop')
    max-height 150px

+mq($until: 'desktop')
  .region-filters
    overflow scroll
    &__inner
      width max-content
</style>