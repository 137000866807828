import {
  WebGLRenderer,
  sRGBEncoding,
  PCFSoftShadowMap,
  ReinhardToneMapping,
  ACESFilmicToneMapping,
  WebGLRenderTarget,
  WebGLMultisampleRenderTarget,
  LinearFilter,
  RGBFormat
} from 'three'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'

export class Renderer {
  constructor(scene, camera) {
    this.scene = scene
    this.camera = camera
    
    this.usePostprocess = false

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)

    this.setInstance()
    this.setPostProcess()

    const Stats = require('stats-js')
    // this.stats = new Stats()
    // this.stats.showPanel(0)
    // document.body.appendChild( this.stats.dom );
  }

  setInstance () {
    this.clearColor = '#009900'

    // Renderer
    this.renderer = new WebGLRenderer({
      alpha: false,
      antialias: true,
    })
    this.renderer.setClearColor(0xfbf6f7)
    // this.renderer.setClearColor(0xe7e5e6)
    // this.renderer.setClearColor(0x333333)

    // this.renderer.physicallyCorrectLights = true
    this.renderer.outputEncoding = sRGBEncoding
    this.renderer.shadowMap.type = PCFSoftShadowMap
    this.renderer.shadowMap.enabled = true
    // this.renderer.toneMapping = ReinhardToneMapping
    this.renderer.toneMapping = ACESFilmicToneMapping
    this.renderer.toneMappingExposure = 1.3
    this.renderer.powerPreference = 'high-performance'
    this.renderer.gammaOutPut = true
    this.renderer.gammaFactor = 2.2

    this.context = this.renderer.getContext()

    // Add stats panel
    // if (this.stats) {
    //   this.stats.setRenderPanel(this.context)
    // }
  }

  setPostProcess () {
    this.postProcess = {}

    /**
     * Render pass
     */
    this.postProcess.renderPass = new RenderPass(this.scene, this.camera)

    /**
     * Effect composer
     */
    const RenderTargetClass = window.devicePixelRatio >= 2 ? WebGLRenderTarget : WebGLMultisampleRenderTarget
    // const RenderTargetClass = WebGLRenderTarget
    this.renderTarget = new RenderTargetClass(
      window.innerWidth,
      window.innerHeight,
      {
        generateMipmaps: false,
        minFilter: LinearFilter,
        magFilter: LinearFilter,
        format: RGBFormat,
        encoding: sRGBEncoding
      }
    )
    this.postProcess.composer = new EffectComposer(this.renderer, this.renderTarget)
    this.postProcess.composer.setSize(window.innerWidth, window.innerHeight)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.postProcess.composer.addPass(this.postProcess.renderPass)
  }

  resize ({width, height}) {
    // Instance
    this.renderer.setSize(width, height)
    this.renderTarget.setSize(width, height)
    this.renderer.setPixelRatio(window.devicePixelRatio)

    // Post process
    this.postProcess.composer.setSize(width, height)
    this.postProcess.composer.setPixelRatio(window.devicePixelRatio)

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
  }

  update () {
    this.stats?.begin()

    if (this.usePostprocess) {
      this.postProcess.composer.render()
    }
    else {
      this.renderer.render(this.scene, this.camera)
    }

    // if (this.stats) {
    //   this.stats.afterRender()
    // }

    this.stats?.end()

  }

  get canvas () {
    return this.renderer.domElement
  }

  destroy () {
    this.renderer.renderLists.dispose()
    this.renderer.dispose()
    this.renderTarget.dispose()
    this.postProcess.composer.renderTarget1.dispose()
    this.postProcess.composer.renderTarget2.dispose()
  }
}
