import data from '@/assets/data.json';
import pointInPolygon from 'point-in-polygon';
import { LoaderManager } from 'shimmer';
import { Box3, Box3Helper, Object3D, Vector3 } from 'three';
import Region from './Region';

const islands = ['pacifique', 'antilles', 'indien']

export class France extends Object3D {
  constructor() {
    super("France")
    this.name = "France"

    this.markers = []
    this.activeMap = "map"

    this.boundingBoxes = []

    this.matrixAutoUpdate = false

    this.loadObject = this.loadObject.bind(this)
    this.coordsToPosition = this.coordsToPosition.bind(this)

    this.regionInstances = []
    this.projectDecoInstances = []
  }

  setActiveMap(mapName) {
    this.activeMap = mapName
  }

  get visibleRegionInstances() {
    return this.regionInstances.filter(instance => instance.mesh)
  }

  addProjectDeco(projectDeco) {
    this.projectDecoInstances.push(projectDeco)
  }

  removeProjectDeco(projectDeco) {
    this.projectDecoInstances = this.projectDecoInstances.filter(instance => instance.id !== projectDeco.id)
  }

  getProjectByEntryTitle(title) {
    return this.projectDecoInstances.find(project => project.title === title)
  }

  disposeProjects() {
    // this.projectDecoInstances.forEach(project => project.dispose())
    this.projectDecoInstances = []
  }

  disposeRegions() {
    this.regionInstances = []
  }

  addMarker(marker) {
    this.markers.push(marker)
  }

  removeMarker(marker) {
    this.markers = this.markers.filter(m => m.id !== marker.id)
  }
  
  async coordsToPosition(coords, y) {
    if ( data.assets.find( asset => asset.name === this.activeMap ).boundingBox ) 
      return await this.coordsToPositionMono( coords, y )
    else return await this.coordsToPositionMulti(coords, y)
  }

  /**
   * Convert a geographic position to a position in Threejs space
   * @param {Object} coords 
   * @param {number} coords.lat
   * @param {number} coords.lng
   * @returns {Vector3}
   */
  async coordsToPositionMono(coords, y = 0.2, geoBoundingBox, threeBoundingBox) {
    // can be optained at https://boundingbox.klokantech.com/, format GeoJSON
    // formatted as long, lat (x, y)
    // bottom left, bottom right, top right, top left
    if (!geoBoundingBox) geoBoundingBox = data.assets.find(asset => asset.name === this.activeMap).boundingBox
    if (!threeBoundingBox) threeBoundingBox = this.mapBbox
    
    const xRatio = (coords.lng - geoBoundingBox[0][0]) / (geoBoundingBox[1][0] - geoBoundingBox[0][0])
    const zRatio = (coords.lat - geoBoundingBox[1][1]) / (geoBoundingBox[2][1] - geoBoundingBox[1][1])
    
    threeBoundingBox = await threeBoundingBox
    
    const x = (threeBoundingBox).min.x + 
      (threeBoundingBox.max.x - 
        threeBoundingBox.min.x) 
          * xRatio
    const z = threeBoundingBox.max.z - (threeBoundingBox.max.z - threeBoundingBox.min.z) * zRatio

    return new Vector3(x, y, z)
  }

  async coordsToPositionMulti(coords, y = 0.92) {
    const boundingBoxes = data.assets.find(asset => asset.name === this.activeMap).boundingBoxes

    if (!this.boundingBoxes.length) {
      console.warn('this.boundingboxes empty')
      return
    }

    for (const box of boundingBoxes) {
      // we check wether point should be placed according to this island
      if (pointInPolygon([coords.lng, coords.lat], box.limits))  {
        const bbox = this.boundingBoxes.find(bbox => bbox.name === 'box_' + box.name)
        const asyncBox = await bbox.box
        return await this.coordsToPositionMono(coords, y, box.limits, asyncBox)
      }
    }

    console.log("no polygon found ! :", coords)
    return this.coordsToPositionMono(coords, y, boundingBoxes[0].limits, this.boundingBoxes.find(bbox => bbox.name === 'box_' + boundingBoxes[0].name).box)
  }

  /**
   * Load the map Object
   * @param {string|string[]} [baseMapLayerName] - name of the layer of layers to be used as a reference for map geographic bounds, 
   * corresponding to the bounding box of the map set it data.json. 
   * If not provided, the whole gltf of the map is used. 
   * The use of a single layer is more precise as the computation of a bounding box on a compound object3D 
   * can result in a larger bouding box than strictly necessary.
   * @returns {Promise<Object3D>}
   */
  loadObject({baseMapLayerName = undefined, mapName = 'map', isMultiBoundingBox = false, controls} = {}) {

    return this.isLoaded = LoaderManager.load(mapName, false).then(obj => {
      
      this.mapObject = obj[0].object

      this.add(this.mapObject)


      this.centerMap()
      this.hideUnbakedRegions()

      if (isMultiBoundingBox)
        this.addMultiBoundingBox()
      else
        this.addBoundingBox(baseMapLayerName)

      if (controls) this.setControlsTarget(controls, this.mapBbox)
      
      this.hideHitboxes()

      // this.repositionMarkers()
    })
  }

  centerMap() {
    const center = new Vector3()
    new Box3().setFromObject(this.mapObject).getCenter(center)
    this.mapObject.position.x -= center.x
    this.mapObject.position.z -= center.z

    // fix weird behavior on large regions
    if (this.mapObject.parent.activeMap === 'pacifique') {
      this.mapObject.position.x = -18
      this.mapObject.position.z = -14
    }
  }

  hideUnbakedRegions() {
    this.mapObject.traverse(obj => {
      if (obj.name.startsWith('region_') && !obj.name.endsWith('_Baked')) {
        obj.visible = false
      }
    })
  }

  discardMap() {
    this.regionInstances.forEach(region => region.dispose())
    this.remove(this.mapObject)
    const sol = this.getObjectByName('sol_Baked')
    if (sol) this.remove(sol)
  }

  repositionMarkers() {
    this.markers.forEach(marker => {
      marker.setPosition()
    })
  }

  addBoundingBox(baseMapLayerName) {

    const sol = this.mapObject.getObjectByName('sol_Baked');
    sol?.removeFromParent()
    
    // remove temporarily corse so that the reference is the continental part
    const corse = this.mapObject.getObjectByName('group_corse');
    corse?.removeFromParent()
    
    if ( typeof baseMapLayerName === 'string' ) {

      const mapMesh = this.mapObject.getObjectByName(baseMapLayerName)
      if ( mapMesh.isMesh ) {
        mapMesh.geometry.computeBoundingBox()
        this.mapBbox = mapMesh.geometry.boundingBox
      } else {
        this.mapBbox = new Box3().setFromObject(mapMesh, true)
      }

    } else if ( Array.isArray(baseMapLayerName) ) {

      const baseMap = new Object3D()
      baseMapLayerName.forEach(name => {
        const mesh = this.mapObject.getObjectByName(name)
        // TODO: find better way than clone 
        baseMap.add(mesh.clone())
      })
      this.mapBbox = new Box3().setFromObject(baseMap, true)

    } else {  

      this.mapBbox = new Box3().setFromObject(this.mapObject, true)

    }


    if (sol) { this.mapObject.add(sol); sol.visible = false }
    if (corse) this.mapObject.add(corse)
  }

  addMultiBoundingBox() {
    this.mapObject.traverse(obj => {
      const asset = data.assets.find(asset => asset.name === this.activeMap)
      const isReferenceObject = asset.boundingBoxes.some(box => 'box_' + box.name === obj.name)
      
      if (isReferenceObject) {
          
          obj.visible = false
          this.boundingBoxes.push(
            {
              name: obj.name, 
  
              box: new Promise((resolve) => {
                setTimeout(() => {
                  const box = new Box3().setFromObject(obj)
                  // const helper = new Box3Helper(box)
                  // this.add(helper)
                  resolve(box)
                }, 500);
              })
  
            }
          )

      }

      if (obj.name.startsWith('sol_')) { // quick fix
        obj.visible = false
      }
    })
  }

  /**
   * binds regions groups (threejs) to regions (vuex)
   * @param {Object} regionEntries - regions from vuex
  */
  bindRegions(regionEntries) {
    if (!regionEntries) return

    this.mapObject.traverse(obj => {
      if(obj.name.startsWith('group_')) {
        // extraction of the first word in region name, ex: 'pays' for group_pays_de_la_loire
        const slashIndex = obj.name.indexOf('_')
        const objRegionName = obj.name.slice( slashIndex + 1, obj.name.indexOf('_', slashIndex + 1))
        // attach based on the extracted name
        const index = regionEntries.findIndex(regionEntry => regionEntry.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(objRegionName) )
        if ( index !== -1 ) {
          // const regionInstance = this.regionInstances.find( i => i.region.id === regionEntries[index].id )
          // if ( regionInstance ) {
          //   regionInstance.setRegionMesh( obj )
          // }
          // else 
          // debugger
          this.regionInstances.push(new Region(obj, regionEntries[index]))
        }
      }

      if (islands.includes(obj.name)) {
        const index = regionEntries.findIndex(regionEntry => regionEntry.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(obj.name) )
        if ( index !== -1 ) {
          // const regionInstance = this.regionInstances.find( i => i.region.id === regionEntries[index].id )
          // if ( regionInstance ) {
          //   regionInstance.setRegionMesh( obj )
          // }
          // else 
          // debugger
          this.regionInstances.push(new Region(obj, regionEntries[index], true))
        }
      }
    })

    this.addInvisibleRegions(regionEntries)
    this.addComponents()
  }

  addInvisibleRegions(regionEntries) {
    const invisibleRegions = regionEntries.filter(regionEntry => !this.regionInstances.some(r => r.entry.id === regionEntry.id))
    invisibleRegions.forEach(regionEntry => {
      this.regionInstances.push(new Region(null, regionEntry))
    })
  }

  addComponents() {
    this.visibleRegionInstances.forEach(region => {region.componentify()})
  }

  hideAllHalos() {
    this.visibleRegionInstances.forEach(reg => reg.hideHalo())
  }

  hideAllProjectHalos() {
    this.projectDecoInstances.forEach(project => project['hideHalo']?.())
  }

  setControlsTarget(controls, box) {
    if (!controls) return
    if (!box) box = new Box3().setFromObject(this.mapObject)
    const center = new Vector3()
    box.getCenter(center)
    controls.target.copy(center)
  }

  hideHitboxes() {
    this.mapObject.traverse(child => (child.name.startsWith('hitbox') || child.name.startsWith('box')) && child.visible && (child.visible = false))
    // this.regionInstances.forEach(reg => reg.hideHitbox())
  }

  getRegionByEntryName(name) {
    return this.regionInstances.find(reg => reg.entry.title === name)
  }
  
  getRegionByMeshName(name) {
    return this.regionInstances.find(reg => reg.mesh?.name === name)
  }

  matchRegionByEntryName(name) {
    return this.regionInstances.find(reg => reg.entry.title.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(name))
  }
}

const france = new France()
export default france
export const coordsToPosition = france.coordsToPosition
export const coordsToPositionAsync = france.coordsToPositionAsync

