<template>
  <div class="region">
    <region-deco
      :args="args"
      />
    <transition name="t-fader">
      <name-bubble 
        v-if="!$device.isTablet && isOver && !$store.state.global.isZooming"
        :key="regionEntry.id + 'region'"
        lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
        :name="regionEntry.title"
        :position="position"
        :maxScale="250"
        />
    </transition>
  </div>
</template>

<script>
import RegionDeco from "@/components/3d/RegionDeco";
import NameBubble from "@/components/NameBubble";
import { reactive, onBeforeUnmount, ref } from "vue";
import { Vector3 } from "three";
import { onMounted } from "vue";
import france from "../webGL/objects/France";

export default {
  props: {
      regionEntry: {
        type: Object,
        default: () => ({}),
      },
    },

  components: {
    RegionDeco,
    NameBubble,
  },
  emits: ["select"],

  setup(props, { emit }) {
    let regionInstance = null
    const position = reactive(new Vector3())
    const isOver = ref(false)
    
    const args = reactive({
      geolocalisation: props.regionEntry.geolocalisation,
      object3d: props.regionEntry.object3d,
      degree: props.regionEntry.degree,
      scale: props.regionEntry.scale,
    })

    const selectRegion = () => {
      regionInstance = france.getRegionByEntryName(props.regionEntry.title)
      emit('select', regionInstance)
    }

    const onEnter = () => {
      isOver.value = true
    }

    const onOut = () => {
      isOver.value = false
    }
    
    onMounted(async () => {
      await france.isLoaded
      await france.regionsLoaded
    
      const _position = await france.coordsToPosition(args.geolocalisation)
    
      position.x = _position.x - 6
      position.y = _position.y + 7
      position.z = _position.z

      regionInstance = france.getRegionByEntryName(props.regionEntry.title)

      if (regionInstance.mesh) {
        regionInstance.onClick = selectRegion
        regionInstance.onEnter = onEnter
        regionInstance.onOut = onOut
      }
    })

    onBeforeUnmount(() => {
      // regionInstance.onClick = null
      // regionInstance.onEnter = null
      // regionInstance.onOut = null
    })
    return {
      args,
      position,
      isOver,
    }
  }
}

</script>

<style>

</style>