class YoutubeRegister {
  constructor() {
    this.register = this.register.bind( this )
    this.onInit = this.onInit.bind( this )

    this.callbacks = new Array()

    this.tag = document.createElement('script')
    this.tag.src = "https://www.youtube.com/player_api"
    this.firstScriptTag = document.getElementsByTagName('script')[0]
    this.firstScriptTag.parentNode.insertBefore(this.tag, this.firstScriptTag)

    this.isInit = false

    window.onYouTubePlayerAPIReady = this.onInit
  }

  register( callback ) {
    this.callbacks.push( callback )

    if( this.isInit ) {
      this.onInit()
    }
  }

  onInit() {
    for( let i = 0 ; i < this.callbacks.length ; i++ ) {
      this.callbacks[i]()
    }

    this.isInit = true
    this.callbacks = new Array()
  }
}

export default new YoutubeRegister();