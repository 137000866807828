export const getReadTime = (text, wordsPerMinute = 100) => {

  if ( !text ) return 1
  
  const words = text.split(/\s+/g)
  const wordsCount = words.length
  const minutes = Math.ceil(wordsCount / wordsPerMinute)
  
  return minutes
}

// var min = Math.floor(postContent.text().replace(/\r?\n/g,'').replace(/\s{2,}/g, ' ').trim().split(' ').length / 200) || '< 1';
