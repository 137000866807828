<template>
  <div class="c-video-vimeo u-box-16by9 u-overflow-hidden u-font-reset">
    <div class="u-wrapper-panel" ref="player"></div>

    <div v-if="poster !== ''" class="c-video-vimeo__poster u-wrapper-panel">
      <img class="u-fit-cover" :src="poster">
    </div>

    <div class="u-wrapper-panel u-row u-middle u-center u-z-middle u-cursor-pointer" @click="onTogglePlay">
      <div class="c-video-vimeo__poster u-w1of12 u-box-1by1 u-round u-bg-white">
        <div class="u-wrapper-panel u-flex u-center u-middle">
          <div>
            <div class="t-text u-blue">
              Play
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Player from  '@vimeo/player'

export default
{
  props: {
    id: { type: String, default: '' },
    poster: { type: String, default: '' },
    isPlaying: { type: Boolean, default: false },
    isMuted: { type: Boolean, default: false },
    isControls: { type: Boolean, default: true },
    newCurrentTime: { type: Number, default: 0 },

    onPlay: { type: Function, default: ()=>{ return } },
    onPause: { type: Function, default: ()=>{ return } }
  },

  watch: {
    isPlaying: 'onActiveChange',
    isFullScreen: 'onFullScreenChange',
    isMuted: 'onMutedChange',
    id: 'onSrcChange',
    newCurrentTime: 'onNewCurrentTimeChange'
  },

  created() {
    this._duration = 0
    this._isPlaying = false
    this._isFullScreen = false
  },

  mounted() {
    this.createPlayer()
  },

  methods: {
    onActiveChange() {
      if(this.isPlaying && this._YTplayer)
      {
        this.onPlayerPlay()
      }
      else
      {
        this.onPlayerPause()
      }
    },

    onMutedChange() {
      this.isMuted != this.isMuted
      this.player.setMuted(this.isMuted)

    },

    onNewCurrentTimeChange() {
      this.player.setCurrentTime(this.newCurrentTime * this._duration)
    },

    createPlayer() {
      this.options = {
        id: this.id,
        responsive: true,
        loop: 0,
        controls: this.isControls,
        autoplay: this.isPlaying,
        muted: this.isMuted,
        background: false,
        rel: 0
      };

      this.player = new Player( this.$refs.player, this.options )

      this.player.on('ready', this.onPlayerReady)
      this.player.on('play', this.onPlayerPlay)
      this.player.on('ended', this.onPlayerEnd)
      this.player.on('pause', this.onPlayerPause)
    },

    onSrcChange() {
      // if(this._YTplayer != undefined)
      // {
      //   this.$emit('currentTime', 0)
      //   this._YTplayer.cueVideoById(this.id)
      // }
    },

    onPlayerReady() {
      this.getDuration()
    },

    getDuration() {
      this._duration = this.player.getDuration()
      this.$emit('duration', this._duration)
    },

    onPlayerPlay() {
      this.getDuration()
 
      this._isPlaying = true

      this.$emit('play')
      this.$el.classList.add('is-playing')
      this.$el.classList.remove('is-paused')
      this.$el.classList.add('is-started')
      this.onPlay()

      this.player.play()
    },

    onPlayerPause() {
      this._isPlaying = false

      this.$emit('pause')
      this.$el.classList.remove('is-playing')
      this.$el.classList.add('is-paused')
      this.onPause()

      this.player.pause()
    },

    onTogglePlay() {
      this._isPlaying ? this.onPlayerPause() : this.onPlayerPlay()
    },

    onPlayerEnd() {
      this.$el.classList.remove('is-playing')
      this.$el.classList.remove('is-started')

      this.$emit('end')
    },

    onPlayerError(err) {
      console.warn('onPlayerError', err)
    },

    onUpdate() {
      if(this.player && this.player.getCurrentTime && this._isPlaying) {
        this.player.getCurrentTime().then(res => {
          this.$emit('currentTime', res)
        })
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.c-video-vimeo
  &__poster
    will-change opacity
    transform translateZ(0)
    transition opacity 1s linear
    
    &after
      position absolute
      content ''
      top 0
      left 0
      width 100%
      height 100%
      background #000
      opacity .3
  
    .is-started &
      opacity 0
      transition opacity 3s linear

  &__play
    width 13px
    height 20px
    
    opacity 1
    transform translateZ(0)
    transition opacity .45s ease-out-quart

    .is-playing &
      opacity 0

  &__pause
    width 11px
    height 20px
    
    opacity 0
    transform translateZ(0)
    transition opacity .45s ease-out-quart
    
    .is-playing &
      opacity 1

</style>