<template>
  <div class="u-pointer-none u-z-front">
    <span
      :to="to"
      ref="root"
      class="keypoint u-cursor-pointer"
      :class="{'is-active': isVisible}"
    >
      <div :style="{ 'font-size': size+'px' }" class="keypoint__text u-pad-md u-bg-red t-circular t-bold t-text--small">
        {{ name }}
      </div>
    </span>
  </div>
</template>

<script>

export default {

  emits: ['click'],

  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 12
    },
  }
}
</script>

<style scoped lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint
  border-radius 50%
  width 36px
  opacity 0
  transition opacity .3s linear

  // +mq($until: 'tablet')
  //   width 20px

  &__text
    max-width 40ch
    z-index 20000
    position absolute
    left calc(50%)
    top 10vh
    transform translate(-50%, 0)
    width max-content
    border-radius 12px
    color #ececec
    text-shadow: 0px 0px 1px #ffa19c,
    1px 12px 35px rgba(16,16,16,0.2),
    1px 16px 60px rgba(16,16,16,0.4);
    // text-shadow: 1px 1px 0px rgb(209,46,38), -1px -1px 0px rgb(209,46,38)	;
  
  &.is-active
    opacity 1
  
  &__main
    border-radius 50%
    background-color $colors.second-color

  &__heart
    position absolute
    background: $colors.main-color
    height 30%
    width 30%
    top 35%
    left 35%
    border-radius 50%

  &__pulse
    position absolute
    border solid 2px $colors.main-color
    height 200%
    width 200%
    top -50%
    left -50%
    border-radius 50%
    opacity 0
    will-change opacity transform

</style>