import { Component } from 'shimmer'

import { CylinderGeometry, MeshBasicMaterial, Mesh, Color,  } from 'three'

export class Pin extends Component {
  constructor ({ height = 1.8, color }) {
    super('Pin')

    this.geometry = new CylinderGeometry(0.08, 0.08, height, 8, 1, true)

    this.material = new MeshBasicMaterial({ color: new Color(color), opacity: 1, depthWrite: false, depthTest: false })
    this.material.transparent = true

    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.position.z += height * .5
    this.mesh.rotation.x = Math.PI * .5
    this.add(this.mesh)

    this.material.color.convertSRGBToLinear()
  }
}