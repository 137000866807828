<template>
  <div>
    <region-slider
      currentRegionName="currentRegionName"
      @next="emit('nextRegion')"
      @prev="emit('prevRegion')"
      v-if="!$device.isMobile"
      />
    <router-view v-slot="{ Component }">
      <transition
        :name="
          $device.isMobile
            ? 't-translate--up-delayed'
            : 't-translate--right-delayed'
        "
      >
        <component    
          @toggleConseiller="toggleConseiller"
          @homeView="emit('homeView')"
          @nextRegion="emit('nextRegion')"
          @prevRegion="emit('prevRegion')" 
          :data="{categories}"
          :is="Component" />
      </transition>
    </router-view>

    <div v-for="(entry, index) in visibleRegionEntries" :key="entry.id + 'marker'" class="project">
      <project-obj
          :args="{ index, ...entry }"
          :isDomTom=" regionInstance.isDomTom "
        />
    </div>

    <transition name="t-fader">
      <conseiller
        v-if="isConseillerOnDisplay"
        @toggleConseiller="toggleConseiller"
        :contactPhoto="regionEntry.image?.[0]?.id"
        :regionName="regionEntry.title"
        :contactName="regionEntry.personName"
        :address="regionEntry.btContactPostalAddress"
        :phone="regionEntry.btContactPhone"
        :email="regionEntry.btContactEmail"
        :link="regionEntry.btContactLink"
        />
    </transition>

    <button @click.stop="toggleConseiller" :class="[$device.isMobile ? 'mail-button--mobile u-flex u-center u-middle' : 'u-radius--bigger', $device.isPortraitDesktop ? 'mail-button--vert' : '']" class="u-z-front u-pad-y-sm u-pad-x-lmd u-bg-red u-color-white u-inline-flex u-absolute mail-button u-middle u-between u-white">
      <svg :class="{'u-marg-r-sm': !$device.isMobile}" class="u-fit-contain u-fill-white" >
        <use xlink:href="#write-picto"/>
      </svg>
      <span v-if="!$device.isMobile">
        Contacter votre direction régionale
      </span>
    </button>
  </div>
</template>

<script setup>
import ProjectObj from "@/components/3d/ProjectObj";
import Conseiller from "@/components/Conseiller";
import RegionSlider from "@/components/RegionSlider";
import france from "@/webGL/objects/France";
import { webGL } from "@/webGL/WebGL";
import gsap from "gsap";
import { FitTo } from 'shimmer';
import { Vector3 } from "three";
import { computed, onUpdated, ref, onBeforeUnmount, inject } from 'vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { cartesianToPolar } from "../../utils/Maths";
import { gui } from "../../utils/GUI2";
import data from '@/assets/data.json';


const store = useStore()
const route = useRoute()
const device = inject('device')

const isConseillerOnDisplay = ref(false)

const categories = computed(() => {
  return store.getters['data/realisationCategories']
})

const toggleConseiller = () => {
  isConseillerOnDisplay.value = !isConseillerOnDisplay.value
}

const regionEntry = computed(() => {
  return store.state.global.currentRegion
})

let regionInstance = france.getRegionByEntryName(regionEntry.value.title)

const visibleRegionEntries = computed(() => {
  const activeCategories = store.state.global.selectedRealCategories
  
  const activeEntries = store.state.data.entries
    .filter(entry => entry.typeHandle !== 'btRegion')
    .filter(entry => entry.fontSize || activeCategories.length === 0 || activeCategories.includes(entry.btCatReal?.[0].id))
    
  return activeEntries.filter(entry => entry.region?.id === regionEntry.value.id)
})

const emit = defineEmits(["nextRegion", "prevRegion", "homeView"])

// default
const zoomRegionOptions = {
  // padding: device.isMobile.value ? {top: 0., right: 0., bottom: .9, left: 0.} : {top: 2.6, right: 2.5, bottom: 5.6, left: 1.68},
  padding: device.isMobile.value 
    ? {top: 0., right: 0., bottom: .9, left: 0.} 
    : device.isWide.value
      ? {top: 1.3, right: 0.92, bottom: 4.9, left: 0.57}
      : {top: 1.3, right: 0.9, bottom: 4.9, left: 0.5},
  vector: new Vector3(-0.5, 1.25, 1)
}

const asset = data.assets.find(asset => asset.name === france.activeMap)
if ( asset?.padding ) zoomRegionOptions.padding = device.isMobile.value 
  ? asset?.padding.mobile
  : asset?.padding.desktop

const placeCamera = () => {

  france.hideAllProjectHalos()

  let opts = {...zoomRegionOptions}

  if ( regionInstance.lowerName === 'aquitaine') {
    opts.padding = device.isMobile.value 
      ? { top: 0, bottom: 7, right: 0, left: 0 } 
      : { top: 4, right: 0.9, bottom: 10.7, left: 0.76 }
  }

  if ( regionInstance.lowerName === 'centre_val_de_loire' ) {
    opts.padding = device.isMobile.value 
      ? zoomRegionOptions.padding
      : {top: 1.3, right: 1.45, bottom: 4.9, left: 0.70}
  }

  regionInstance.updateMatrixWorld()

  const { position, look } = FitTo.fit(regionInstance.box, opts.padding, {
    vector: zoomRegionOptions.vector,
  }, 'contain');
  position.z += 2
  look.z += 2

  store.commit('global/setZooming', true)

  webGL.regionPan.blockOob = false

  if (regionInstance.isDomTom) webGL.controls.maxDistance = 200

  gsap.to(webGL.camera.position, {
    x: position.x,
    y: position.y,
    z: position.z,
    duration: 2,
    ease: "power4.inOut",
    onComplete: () => {
      webGL.regionPan.blockOob = true
      store.commit('global/setZooming', false)
    }
  });
  
  gsap.to(webGL.camera.look, {
    x: look.x,
    y: look.y,
    z: look.z,
    duration: 2,
    ease: "power4.inOut",
  });

  gsap.to(webGL.controls?.target, {
    x: look.x,
    y: look.y,
    z: look.z,
    duration: 2,
    ease: "power4.inOut",
  });

  webGL.regionPan.isActive = false
  webGL.regionPan.regionBox = regionInstance.box
  webGL.controls.enablePan = true
}

const folder = gui.addFolder('Zoom Region padding').onChange(() => placeCamera())
folder.add(zoomRegionOptions.padding, 'top', -5, 5)
folder.add(zoomRegionOptions.padding, 'right', 0, 5)
folder.add(zoomRegionOptions.padding, 'bottom', 0, 15)
folder.add(zoomRegionOptions.padding, 'left', 0, 5)
folder.close()
const folderVector = gui.addFolder('Zoom Region vector').onChange(() => placeCamera())
folderVector.add(zoomRegionOptions.vector, 'x', -5, 5)
folderVector.add(zoomRegionOptions.vector, 'y', -5, 5)
folderVector.add(zoomRegionOptions.vector, 'z', -5, 5)
folderVector.close()

placeCamera()
onUpdated(() => {
  regionInstance = france.getRegionByEntryName(regionEntry.value.title)
  if (route.meta.level === 'Region') {
    placeCamera()
    folder.destroy()
    folderVector.destroy()
  }
})

onBeforeRouteUpdate((to, from, next) => {
  // if (to.meta.level === 'Region') placeCamera()
  // next()
  // console.log(to, from)
  next()
})

onBeforeUnmount(() => {
  france.disposeProjects()
  folder.destroy()
  folderVector.destroy()
})

</script>

<style scoped lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.mail-button
  bottom 34px
  left 150px
  padding-bottom 15px
  padding-top 15px

  svg
    width 30px
    height 30px
  &--mobile
    top 20px
    right 20px
    border-radius 50px
    bottom initial
    left initial
    height 60px
    width 60px
    z-index 500 !important
    padding 20px !important
  &--vert
    height 90px
    width  90px
    border-radius 150px
    padding 30px
    svg
      width 45px
      height 45px
      margin auto
</style>