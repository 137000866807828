<template>
    <div 
      class="u-relative u-pad-x-sm t-button--simple is-button u-row u-shadow u-middle tag" 
      :class="[ active ? 'is-active' : '', 'u-bg-' + 'white' ]"  
      :style="{ backgroundColor: active ? content.color : 'white' }"
      @click.stop="onToggleTag">
        <app-image :style="{ color: active ? 'white' : content.color }" :height="15" class="u-pad-xs u-pad-0--sm u-pad-r-xs--sm tag__picto" :id="content.image?.[0]?.id" alt=""/>
        <p class="t-text--small u-pad-xs u-pad-0--sm u-pad-l-xs--sm u-relative u-uppercase">{{content.title}}</p>
    </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { useStore } from "vuex"

import AppImage from "@/components/common/AppImage"

const store = useStore()

const props = defineProps({
  content:{
    type: Object,
    default: null
  },
  clickable: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits({
  onToggleTag: {
    type: Boolean,
    default: true
  }
})

const isActive = ref(store.state.global.selectedRealCategories.includes(props.content?.id))

const active = computed(() => (isActive.value || !props.clickable))

const onToggleTag = () => {
  if (!props.clickable) return

  isActive.value = !isActive.value

  store.commit('global/toggleSelectedRealCategory', props.content.id)

  emit('toggle:tag', props.content.id)
}

</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'

.tag
  border-radius 14px
  padding: 5px 12px !important
  color black
  cursor pointer
  display inline-flex !important
  box-shadow: 0px 1.52634px 9.92119px rgba(0, 0, 0, 0.1);
  +mq($until: 'tablet')
    border-radius 7px
    font-size: 10px;
    align-items: center;
    font-family: 'Gotham';
    font-weight: 700;

  &__picto
    filter none

  &.is-active
    color white

    // .tag__picto
    //   filter invert(1)
</style>