import DecoObjectLazy from "./DecoObjectLazy"
import { Box3, CylinderBufferGeometry, Mesh, Vector3, MeshBasicMaterial } from "three"

import { haloMaterial } from "./Region"
import gsap from "gsap"

export default class Project extends DecoObjectLazy {
  constructor(deco, listeners, isDomTom) {
    super(deco)
    this.bbox = null
    this.geo = null
    this._center = null
    this.isSelected = false
    this.region = deco.region.title
    this.ready.then(() => {
      this.setBbox()
      this.setHitBox()
      setTimeout(() => {
        this.setHalo( this.mesh, isDomTom )
        this.setupListeners(listeners)
      }, 500);
    })
  }

  setBbox() {
    this.geo = this.mesh.geometry
    this.bbox = new Box3().setFromObject(this.mesh)
  }

  get boundingBox() {
    return new Box3().setFromObject(this.mesh)
  }

  setupListeners({onEnter, onOut, onClick = null}) {
    if (window.innerWidth > 1024) {
      this.on('enter', onEnter, this.hitbox)
      this.on('out', onOut, this.hitbox)
    }
    if (onClick) this.on('click', onClick, this.hitbox)
  }

  setHitBox() {
    const width = Math.max(this.bbox.max.x - this.bbox.min.x, this.bbox.max.z - this.bbox.min.z)
    const geo = new CylinderBufferGeometry( width*1.1, width*1.1, 0.5, 32 )
    const mat = new MeshBasicMaterial({transparent: true, opacity: 0, color: 0xffffff, depthWrite: false})
    const mesh = new Mesh(geo, mat)
    this.hitbox = mesh
    const pos = new Vector3()
    this.bbox.getCenter(pos)
    pos.y += 1.9
    mesh.position.copy(pos)
    mesh.scale.set(0.4, 1, 0.4)
    this.add(mesh)
    this._center = pos
  }

  setHalo(mesh, isDomTom) {
    this.halo = mesh.clone()
    const object = this.halo.children[0].children[0].children[0]
    
    try {
      object.material = haloMaterial.clone()
      this.add(this.halo)
      this.halo.scale.y = 50
      this.halo.scale.x *= 1.01
      this.halo.scale.z *= 1.01
      
      if (this.region === 'Occitanie') {
        this.halo.scale.y = 50
        this.halo.position.y += 3
      }
  
      this.halo.updateMatrix()
      this.halo.name = "halo"
      this.haloObject = object
  
      if (isDomTom) {
        // this.haloObject.material.uniforms.attenuationFactor.value = 0.25
        // this.haloObject.position.y -= 10
        this.halo.visible = false
      }
    } catch (error) {
      console.error(error)
    }

  }

  showFadedHalo() {
    if (this.isSelected) this.showHalo(0.25)
  }

  hideFadedHalo() {
    if (this.isSelected) this.hideHalo()
  }

  showHalo(opacity = 0.5) {
    gsap.to(this.haloObject.material.uniforms.opacity, {
       value: opacity,
      duration: 2,
      ease: 'power4.inOut'
    })
  }

  hideHalo() {
    if (this.haloObject) {
      gsap.to(this.haloObject.material.uniforms.opacity, {
         value: 0,
        duration: 2,
        ease: 'power4.inOut'
      })
    }
  }
}