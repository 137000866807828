
<template>
  <div></div>
</template>

<script>
import { webGL } from '@/webGL/WebGL'
import Marker from '@/webGL/objects/ProjectMarker'
import map from '@/webGL/objects/France'

export default {
  props: {
    args: {
      type: Object,
      default: {}
    },
  },

  data() {
    return {
      isHover: false,
      position: {x: 0, y:0, z:0}
    }
  },

  // components: {
  //   NameBubble,
  // },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.marker = new Marker({
        ...this.$props.args,
      })
      map.addMarker(this.marker)
      webGL.scene.add(this.marker)
    },
  },

  beforeUnmount () {
    map.removeMarker(this.marker)
    this.marker.visible = false
    if (this.marker.beforeDestroy) this.marker.beforeDestroy()
    setTimeout(() => {
      this.marker?.traverse(child => {
        if (child.type === 'Mesh') {
          child.geometry.dispose()
          if (Array.isArray(child.material)) {
            child.material.forEach(material => {
              material.aoMap?.dispose()
              material.alphaMap?.dispose()
              material.bumpMap?.dispose()
              material.displacementMap?.dispose()
              material.normalMap?.dispose()
              material.emissiveMap?.dispose()
              material.envMap?.dispose()
              material.lightMap?.dispose()
              material.metalnessMap?.dispose()
              material.roughnessMap?.dispose()
              material.map?.dispose()
              material.dispose()
            })
          } else {
            child.material.aoMap?.dispose()
            child.material.alphaMap?.dispose()
            child.material.bumpMap?.dispose()
            child.material.displacementMap?.dispose()
            child.material.normalMap?.dispose()
            child.material.emissiveMap?.dispose()
            child.material.envMap?.dispose()
            child.material.lightMap?.dispose()
            child.material.metalnessMap?.dispose()
            child.material.roughnessMap?.dispose()
            child.material.map?.dispose()
            child.material.dispose()
          }
        }
      })
      this.marker.off('hover', () => this.isHover = true)
      this.marker.off('out', () => this.isHover = false)
      this.marker.destroy()
      webGL.scene.remove(this.marker)
      // map.markers.
      this.marker = null
    }, 1500)
  }
}
</script>