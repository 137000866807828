<template>
  <button @click.stop="toggleDeployed" v-if="$device.isMobile && isHalfHeight" :class="{'isDeployed': isDeployed}" class="u-shadow--small see-more u-uppercase t-extrabold u-radius--big u-bg-white u-red u-absolute">Voir {{ !isDeployed ? 'plus' : 'moins' }}</button>
  <div v-if="hasOverlay || ($device.isMobile && articleView)" class="overlay"></div>
  <div @click.stop="() => {}" 
  :class="{
    invisible: !isVisible, 
    'u-half-height--sm': isHalfHeight, 
    'isHalfHeight': isHalfHeight,
    'article-view': articleView,
    'u-full-height': isFullHeight,
    'isDeployed': isDeployed,
    'u-full-width': isFullWidth,
    }" 
  class="
    panel 
    u-z-front 
    u-overflow-y-scroll 
    u-bg-white 
    u-black 
    u-pad-md 
    u-cursor-default
    u-pad-t-0--sm
    ">
    <div class="u-full-height u-relative">

      <div :class="{ 'full-width': isFullWidth }" class="panel-question__scroller u-invisible-scroll u-pad-b-lg">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
// const props = defineProps({
//   foo: String
// })

import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

defineEmits(['prev', 'next', 'homeView'])

defineProps({
  isFullWidth: Boolean,
  hasOverlay: Boolean,
})

const route = useRoute()

const isVisible = computed(() => {
  // return true
  return !route.path.includes('/read')
})

const articleView = computed(() => {
  return route.path.includes('/realisation')
})

const listingView = computed(() => {
  return route.path.includes('/listing') || route.path.includes('/realisation')
})

const isHalfHeight = computed(() => {
  return !articleView.value && !listingView.value
})

const isFullHeight = computed(() => {
  return listingView.value
})

const isDeployed = ref(false)
const toggleDeployed = () => {
  isDeployed.value = !isDeployed.value
}

</script>

<style lang="stylus" scoped>
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.see-more
  top: calc(50% + 80px);
  z-index 500;
  width: 36%;
  left: 32%;
  height: 34px;
  font-size 11px

  &.isDeployed
    top: calc(15% + 80px);

.panel
  position absolute
  right 0
  top 0
  height 100%
  width clamp(500px, 37%, 630px)
  transition height 0.3s ease
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar
    display: none;
  padding 60px 30px 20px 45px

  &.article-view
    padding 40px 38px 20px 38px
    z-index: 500;

  &.u-full-width
    width 100%

  &.invisible
    right -120vw
    // transform translateX(0%)

  &__see_more
    top 0

  &-question__scroller.full-width
    +mq($from: 'desktop')
      padding 0 160px

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )
    border-radius 20px 20px 0 0
    padding-top 0
    padding 0 24px 20px 24px

    &.isHalfHeight
      height calc( 50% - 100px )    
    &.isDeployed
      height calc( 85% - 100px ) !important

    &.article-view
      margin: 10px;
      box-sizing: border-box;
      width: 95%;
      height: 95%;
      z-index 2000
      padding 40px 20px 20px 20px
      bottom -20px
      left -2px
      
  +mq($from: 'desktop', $vertical: true)
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )
    border-radius 20px 20px 0 0
    padding-top 0
    padding 0 24px 20px 24px

    &.isHalfHeight
      height calc( 50% - 100px )    
    &.isDeployed
      height calc( 85% - 100px ) !important

    &.article-view
      margin: 10px;
      box-sizing: border-box;
      width: 95%;
      height: 95%;
      z-index 2000
      padding 40px 20px 20px 20px
      bottom -20px
      left -2px

  &__content
    height calc(100% - 60px)
  
  &__button
    height 30px
    width 30px

.overlay
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background-color rgba(0,0,0,0.5)
  z-index: 1
  transition opacity 0.3s ease
  pointer-events none
</style>