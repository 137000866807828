import gsap from 'gsap'
// import debounce from 'lodash.debounce'
import {
  PerspectiveCamera as BasePerspectiveCamera,
  OrthographicCamera as BaseOrthographicCamera,
  Vector3
} from 'three'
import { webGL } from './WebGL'
import Hammer from 'hammerjs'


export class PerspectiveCamera extends BasePerspectiveCamera {
  constructor () {
    super(...arguments)

    this.update = this.update.bind(this)

    this._lookAt = new Vector3(0, 0, 0)
    this._lastLookAt = new Vector3(0, 0, 0)
    this.forceUpdateLook = false
    this.basePosition = new Vector3(0, 0, 0)
    this.onWheel = this.onWheel.bind(this)
  }

  get look () {
    return this._lookAt
  }

  set look (target = new Vector3()) {
    this._lookAt.copy(target)
    this.lookAt(target.x, target.y, target.z)
    this._lastLookAt.copy(this._lookAt)
  }

  handleZoom () {
    
    webGL.hammer = new Hammer(webGL.container)
    webGL.hammer.get('pinch').set({ enable: true })

  }

  onWheelBase (e) {
    console.log('mouse wheel')
  }

  onWheel (e) {

    const delta = e.deltaY
    const zoom = delta > 0 ? 1.015 : 0.985

    const zoomHelper = { level: this.getFocalLength() }
    gsap.to(zoomHelper, {
      level: this.getFocalLength() * zoom,
      duration: 0.5,
      ease: 'power4.out',
      onUpdate: () => {
        console.log('no eased zoom')
      }
    })
  }

  zoomTo(zoom = 1, duration = 0.5) {
    gsap.to(this, {
      zoom,
      duration,
      ease: 'power4.out',
      onUpdate: () => {
        this.updateProjectionMatrix()
      }
    })
  }

  update () {
    if (this._lookAt.x !== this._lastLookAt.x || this._lookAt.y !== this._lastLookAt.y || this._lookAt.z !== this._lastLookAt.z || this.forceUpdateLook) {
      this.look = this._lookAt
    }

    this.forceUpdateLook = false
  }
}

export class OrthographicCamera extends BaseOrthographicCamera {
  constructor () {
    super(...arguments)

    this._lookAt = new Vector3(0, 0, 0)
  }

  get look () {
    return this._lookAt
  }

  set look (target = new Vector3()) {
    this._lookAt = target
    this.lookAt(target.x, target.y, target.z)
  }
}