<template>
  <div class="c-video-youtube u-box-16by9 u-overflow-hidden u-font-reset">
    <div class="u-wrapper-panel" :id=" id + '-video' + suffix "></div>

    <div v-if="poster !== ''" class="c-video-youtube__poster u-wrapper-panel">
      <img class="u-fit-cover" :src="poster">
    </div>

    <div class="u-wrapper-panel u-row u-middle u-center u-z-middle u-cursor-pointer" @click="onTogglePlay">
      <div class="c-video-youtube__poster u-w1of8 u-box-1by1 u-round u-bg-red">
        <div class="u-wrapper-panel u-flex u-center u-middle">
          <div>
            <div class="t-text u-white">
              Play
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YoutubeRegister from './YoutubeRegister.js'

export default
{
  props: {
    id: { type: String, default: '' },
    poster: { type: String, default: '' },
    isPlaying: { type: Boolean, default: false },
    isMuted: { type: Boolean, default: false },
    isControls: { type: Boolean, default: false },
    newCurrentTime: { type: Number, default: 0 },

    suffix: { type: String, default: '' },

    onPlay: { type: Function, default: ()=>{ return } },
    onPause: { type: Function, default: ()=>{ return } }
  },

  watch: {
    isPlaying: 'onActiveChange',
    isFullScreen: 'onFullScreenChange',
    isMuted: 'onMutedChange',
    id: 'onSrcChange',
    newCurrentTime: 'onNewCurrentTimeChange'
  },

  created() {
    this._duration = 0
    this._isPlaying = false
    this._isFullScreen = false
  },

  mounted() {
    YoutubeRegister.register( this.createPlayer )
  },

  methods: {
    onActiveChange() {
      if(this.isPlaying && this._YTplayer)
      {
        this.onPlayerPlay()
      }
      else
      {
        this.onPlayerPause()
      }
    },

    onMutedChange() {
      this.isMuted ? this._YTplayer.mute() : this._YTplayer.unMute()
    },

    onNewCurrentTimeChange() {
      this._YTplayer.seekTo(this.newCurrentTime * this._duration)
    },

    createPlayer() {
      this._YTplayer = new YT.Player(this.id + '-video' + this.suffix, {
        height: '100%',
        width: '100%',
        videoId: this.id,
        playerVars: {
          wmode: 'transparent',
          controls: this.isControls ? 1 : 0,
          showinfo: this.isControls ? 1 : 0,
          modestbranding: this.isControls ? 0 : 1,
          autoplay: 0,
          loop: 0,
          rel: 0,
          modestbranding: 0,
          html5: 1
        },
        events: {
          onReady: this.onPlayerReady.bind(this),
          onStateChange: this.onPlayerStateChange.bind(this),
          onError: this.onPlayerError.bind(this)
        }
      })
      // console.log(this._YTplayer)
      this.$emit('currentTime', 0)
    },

    onSrcChange() {
      if(this._YTplayer != undefined && this._YTplayer.cueVideoById)
      {
        this.$emit('currentTime', 0)
        this._YTplayer.cueVideoById(this.id)
      }
    },

    onPlayerReady() {
      this.getDuration()
    },

    getDuration() {
      this._duration = this._YTplayer.getDuration()
      this.$emit('set:duration', this._duration)
    },

    onPlayerStateChange(event) {
      if(event.data === 0)
      {
        this.onPlayerEnd()
      }
      else if(event.data === 1)
      {
        this.onPlayerPlay()
      }
      else if(event.data === 2)
      {
        this.onPlayerPause()
      }
    },

    onPlayerPlay() {
      this.getDuration()

      this._isPlaying = true

      this.$emit('play')
      this.$el.classList.add('is-playing')
      this.$el.classList.remove('is-paused')
      this.$el.classList.add('is-started')
      this.onPlay()

      this._YTplayer.playVideo()
    },

    onPlayerPause() {
      this._isPlaying = false

      this.$emit('pause')
      this.$el.classList.remove('is-playing')
      this.$el.classList.add('is-paused')
      this.onPause()

      this._YTplayer.pauseVideo()
    },

    onTogglePlay() {
      this._isPlaying ? this.onPlayerPause() : this.onPlayerPlay()
    },

    onPlayerEnd() {
      this.$el.classList.remove('is-playing')
      this.$el.classList.remove('is-started')

      this.$emit('end')
    },

    onPlayerError(err) {
      console.warn('onPlayerError', err)
    },

    onUpdate() {
      if(this._YTplayer && this._YTplayer.getCurrentTime && this._isPlaying)
      {
        this.$emit('currentTime', this._YTplayer.getCurrentTime())
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'

.c-video-youtube
  &__poster
    will-change opacity
    transform translateZ(0)
    transition opacity 1s linear
    
    &after
      position absolute
      content ''
      top 0
      left 0
      width 100%
      height 100%
      background #000
      opacity .3
  
    .is-started &
      opacity 0
      transition opacity .25s linear

  &__play
    width 13px
    height 20px
    
    opacity 1
    transform translateZ(0)
    transition opacity .45s ease-out-quart

    .is-playing &
      opacity 0

  &__pause
    width 11px
    height 20px
    
    opacity 0
    transform translateZ(0)
    transition opacity .45s ease-out-quart
    
    .is-playing &
      opacity 1

</style>