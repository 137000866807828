
<template>
  <!-- <transition name="t-fader">
    <name-bubble
      lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
      :to="'/regions/' + args.id"
      :name="args.title"
      :position="correctedBubblePosition(args.geolocalisation)"
      />
  </transition> -->
  <div :class="args.title + '-deco'"></div>
</template>

<script>
import { webGL } from '@/webGL/WebGL'
import DecoObjectLazy from '@/webGL/objects/DecoObjectLazy'
import { coordsToPosition } from '../../webGL/objects/France'

export default {
  props: {
    args: {
      type: Object,
      default: {}
    },
  },

  data(){
    return {
      isHover: false
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.regionObject = new DecoObjectLazy({
        ...this.$props.args,
        isRegionDeco: true
      })
      webGL.scene.add(this.regionObject)
    },
    async correctBubblePosition(coords) {
      const pos = await coordsToPosition(coords)
      pos.x -= 0.5
      pos.y += 2
    
      return pos
    },
  },

  beforeUnmount () {
    this.regionObject.visible = false
    if (this.regionObject.beforeDestroy) this.regionObject.beforeDestroy()
    setTimeout(() => {
      this.regionObject?.traverse(child => {
        if (child.type === 'Mesh') {
          child.geometry.dispose()
          if (Array.isArray(child.material)) {
            child.material.forEach(material => {
              material.aoMap?.dispose()
              material.alphaMap?.dispose()
              material.bumpMap?.dispose()
              material.displacementMap?.dispose()
              material.normalMap?.dispose()
              material.emissiveMap?.dispose()
              material.envMap?.dispose()
              material.lightMap?.dispose()
              material.metalnessMap?.dispose()
              material.roughnessMap?.dispose()
              material.map?.dispose()
              material.dispose()
            })
          } else {
            child.material.aoMap?.dispose()
            child.material.alphaMap?.dispose()
            child.material.bumpMap?.dispose()
            child.material.displacementMap?.dispose()
            child.material.normalMap?.dispose()
            child.material.emissiveMap?.dispose()
            child.material.envMap?.dispose()
            child.material.lightMap?.dispose()
            child.material.metalnessMap?.dispose()
            child.material.roughnessMap?.dispose()
            child.material.map?.dispose()
            child.material.dispose()
          }
        }
      })
      if (this.regionObject.destroy) this.regionObject.destroy()
      webGL.scene.remove(this.regionObject)
    }, 500)
  }
}
</script>