<template>
  <div
    id="main"
    ref="main"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>

    <transition :duration="500" name="custom">
      <loader v-if="!isLoaded || loadingZone" />
    </transition>

    <button
      @click.stop="homeView"
      v-if="!$device.isMobile"
      class="u-absolute u-top-0 u-left-0 u-right-0 u-bottom-0 u-z-front logo"
    >
      <img src="/images/bdtLogo.png" alt="Banque des territoires">
    </button>

    <router-view :controls="controls" v-slot="{ Component }">
      <transition name="t-simple-fader">
        <component 
          @homeView="homeView" 
          @select="selectRegion" 
          @changeZone="changeZone" 
          @prevRegion="() => onChangeRegion('prev')" 
          @nextRegion="() => onChangeRegion('next')" 
          :is="Component" />
      </transition>
    </router-view>

    <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden u-pointer-none"/>
    <debugger v-if="$route.query.debug" />
  </div>
</template>

<script>
import data from '@/assets/data'
import Debugger from '@/components/debugger'
import SymbolsSvg from '@/components/SymbolsSvg'
import Loader from '@/components/common/Loader'
import SmoothPan from '@/mixins/SmoothPan'
import france from '@/webGL/objects/France'
import { webGL } from '@/webGL/WebGL'
import { LoaderManager } from 'shimmer'
import {
AmbientLight,
BoxGeometry,
MeshNormalMaterial,
Mesh
} from 'three'
import App from './App'
import { MapControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default {
  name: 'App',

  mixins: [App, 
  // SmoothPan
  ],

  components: {
    SymbolsSvg,
    Debugger,
    Loader
  },

  data () {
    return {
      activeRegion: null,
      loadingZone: false,
    }
  },

  computed: {
    isLoaded () {
      return this.$store.getters['data/loaded']
    },
  },

  created () {
    this.pointerevent = null
    LoaderManager.config = data.assets
  },

  mounted () {
    const ambient = new AmbientLight(0xffffff)
    webGL.scene.add(ambient)

    webGL.camera.position.set(0, 10, 10)
    this.controls = webGL.controls = new MapControls(webGL.camera, this.$refs.containerWebGL)
    this.controls.addEventListener('change', () => {

      if (webGL.regionPan.regionBox && webGL.regionPan.blockOob) {
        let isOobX = false, isOobZ = false
        if (this.controls.target.x < webGL.regionPan.regionBox.min.x) {
          this.controls.target.setX(webGL.regionPan.regionBox.min.x)
          webGL.camera.position.setX(webGL.regionPan.basePos.x)
          isOobX = true
        }
        if (this.controls.target.x > webGL.regionPan.regionBox.max.x) {
          this.controls.target.setX(webGL.regionPan.regionBox.max.x)
          webGL.camera.position.setX(webGL.regionPan.basePos.x)
          isOobX = true
        }
        if (this.controls.target.z < webGL.regionPan.regionBox.min.z) {
          this.controls.target.setZ(webGL.regionPan.regionBox.min.z)
          webGL.camera.position.setZ(webGL.regionPan.basePos.z)
          isOobZ = true
        }
        if (this.controls.target.z > webGL.regionPan.regionBox.max.z) {
          this.controls.target.setZ(webGL.regionPan.regionBox.max.z)
          webGL.camera.position.setZ(webGL.regionPan.basePos.z)
          isOobZ = true
        }
        if (!isOobX ) {
          webGL.regionPan.basePos.setX(webGL.camera.position.x)
        }
        if (!isOobZ ) {
          webGL.regionPan.basePos.setZ(webGL.camera.position.z)
        }
        webGL.camera.look.copy(webGL.controls.target)
      }
    })
    this.controls.enablePan = true
    this.controls.enableRotate = false
    this.controls.enableZoom = true
    this.controls.minDistance = 7
    this.controls.maxDistance = 145

    this.onResize()

    // window.addEventListener('mousemove', e => webGL.controls.handleMouseMoveRotate(e))

    const urlParts = location.pathname.split('/')
    const regionName = urlParts.includes('region') ? urlParts[ urlParts.length - 1 ] : null

    this.$router.push('/')

    if ( regionName ) {
      setTimeout(() => {
        france.isLoaded.then(() => {
          france.regionsLoaded.then(() => {
            setTimeout(() => {
              const regionInstance = france.matchRegionByEntryName( regionName )
              this.selectRegion( regionInstance )
            }, 200);
          })
        })
      }, 300);
    }

  },

  beforeUnmount () {
    webGL.controls?.dispose()

    this.$refs.main.removeEventListener('pointerdown', webGL.controls?.onPointerDown)
    this.$refs.main.removeEventListener('pointercancel', webGL.controls?.onPointerCancel)
  },

  methods: {
    homeView () {
      france.hideAllHalos()
      france.setControlsTarget(webGL.controls)
      this.$router.push('/')
      if (france.activeMap !== 'map') this.changeZone({zoneName: 'map', isMulti: false})
    },

    selectRegion (regionInstance) {
      this.$router.push(`/region/${regionInstance.mesh.name.replace('group_', '').replace('box_', '')}`);
    
      this.$store.commit("global/setCurrentRegion", regionInstance.entry)
      this.$store.commit("global/activeProjectIndex", null)

      france.hideAllHalos()
      regionInstance.select()
      this.activeRegion = regionInstance
    },

    onChangeRegion (direction) {
      if (this.activeRegion.isDomTom) {
        let nextZone;
        switch (france.activeMap) {
          case 'antilles':
            if (direction === 'prev') nextZone = {zoneName: 'pacifique', isMulti: true};
            else nextZone = {zoneName: 'indien', isMulti: true};
            break;
          case 'pacifique':
            if (direction === 'prev') nextZone = {zoneName: 'indien', isMulti: true};
            else nextZone = {zoneName: 'antilles', isMulti: true};
            break;
          case 'indien':
            if (direction === 'prev') nextZone = {zoneName: 'antilles', isMulti: true};
            else nextZone = {zoneName: 'pacifique', isMulti: true};
            break;
        
          default:
            break;
        }

        this.changeZone(nextZone)
      } else {
        this.changeRegion(direction)
      }
    },

    changeRegion (direction) {
      const regions = france.visibleRegionInstances
      const currentRegion = this.$store.state.global.currentRegion
      const currentIndex = regions.findIndex(regionInstance => regionInstance.entry.id === currentRegion?.id)
      let nextIndex
      
      if (direction === 'next') {
        nextIndex = (currentIndex + 1)%regions.length
      }

      if (direction === 'prev') {
        nextIndex = currentIndex === 0 ? (regions.length - 1):currentIndex-1
      }
    
      this.selectRegion(regions[nextIndex])
    },

    changeZone ({zoneName, isMulti}) {
      if (france.children.length) {
        france.discardMap()
      }

      this.loadingZone = true

      france.setActiveMap( zoneName )

      france
        .loadObject({mapName: zoneName, isMultiBoundingBox: isMulti, controls: webGL.controls})
        .then(() => {
          france.disposeRegions()
          france.bindRegions( this.$store.getters['data/regions'] )
          if ( isMulti ) this.selectRegion( france.getRegionByMeshName( zoneName ) )
          this.loadingZone = false
        });
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

  // .loader
  //   top 50%
  //   left 50%

.logo
  z-index: 1000 !important
  img
    max-width 195px

</style>