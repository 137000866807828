import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import { createI18n } from 'vue-i18n'

// import * as TastyBurgerButton from 'vue-tasty-burgers';

import { store } from '@/store'
import { router } from './router'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
import Dom3d from '@/plugins/Dom3d'

import messages from '@/locales'


import extendedComponent from '@/mixins/extendedComponent'

import App from './App.vue'

import { emitter } from '@/utils/emitter'

class VueArtwork {
  constructor () {
    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.router.beforeEach((to, from, next) => {
      if (from.query.debug) {
        to.query.debug = from.query.debug
      }
      next()
    })
    this.app.use(this.router)

    // i18n
    this.i18n = createI18n({
      locale: 'en',
      messages
    })
    this.app.use(this.i18n)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    this.app.use(Dom3d)

    // Plugin injection for composition api components
    this.app.provide('hub', emitter)

    // // Burger Button
    // this.app.use(TastyBurgerButton)

    this.app.mount('#app')
  }
}

new VueArtwork()

